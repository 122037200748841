import Nav from "../components/navigation/Nav.jsx";
import NavMobile from "../components/navigation/NavMobile.jsx";
import ListMaker from "../features/standard/startpage/ListMaker.jsx";
import ToDoMaker from "../features/standard/startpage/ToDoMaker.jsx";
import LowerNav from "../components/navigation/LowerNav.jsx";

import AppLogout from "../app/auth/AppLogout.js";

//mobile components
import TooSeeListMobile from "../features/mobile/startpage/TooSeeListMobile.jsx";
import TooSeeListMobileTitle from "../features/mobile/startpage/TooSeeListMobileTitle.jsx";

//hooks
import useIsMobile from "../hooks/useMobile.jsx";

//styles
import globalStyles from "../index.module.css";
import "../css/StartPage.css";

export default function App() {
  const isMobile = useIsMobile();

  const standardContent = (
    <AppLogout>
      <Nav />
      <ListMaker />
      <LowerNav />
      <ToDoMaker />
    </AppLogout>
  );

  const mobileContent = (
    <AppLogout>
      <div className="mobile_body_container">
        <NavMobile />
        <TooSeeListMobileTitle />
        <TooSeeListMobile />
      </div>
    </AppLogout>
  );

  return (
    <div className={globalStyles.body_wrapper}>
      {!isMobile ? standardContent : mobileContent}
    </div>
  );
}
