import React from "react";
import styles from "./css/LowerNav.module.css";
import WhiteButton from "../buttons/WhiteButton";
import { useNavigate } from "react-router-dom";

const LowerNav = () => {
  const navigate = useNavigate();

  const handleResourceNavigate = () => navigate("/settings");

  return (
    <div className={styles.lower_nav_bar}>
      <WhiteButton name="Resources" event={handleResourceNavigate} />
      <WhiteButton name="Additional Content" />
    </div>
  );
};

export default LowerNav;
