import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, Outlet } from "react-router-dom";

const RequiredFirstAuth = () => {
  const authenticated = useSelector((state) => state.twofa.isAuthenticated);
  let location = useLocation();

  const content = !authenticated ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <Outlet />
  );

  return content;
};

export default RequiredFirstAuth;
