import { setIBW } from "../../../../utils/helperFunctions";

const IBW = ({ inputs, handleChange, data }) => {
  const ibw = setIBW(inputs.ht, inputs.sex);

  const ibwChecker = ibw === 0 ? "Please enter height & sex" : ibw;
  const percentIBW = (inputs.wt / ibw) * 100;

  return (
    <div>
      <span>IBW:</span>
      <input
        name="ibw"
        style={!isNaN(ibwChecker) ? { width: "1.6rem" } : { width: "10rem" }}
        value={ibwChecker}
        readOnly={true}
        disabled={true}
        onChange={handleChange}
      />
      {!isNaN(ibwChecker) > 0 && (
        <span style={{ marginLeft: "0.1rem" }}>Lbs</span>
      )}
      {inputs.wt && <span style={{ marginLeft: "10px" }}>Percent IBW</span>}
      {inputs.wt && (
        <span style={{ marginLeft: "5px" }}>{Math.round(percentIBW)} %</span>
      )}
    </div>
  );
};

export default IBW;
