//helper functions
import { nutrientCalc } from "../../../../utils/helperFunctions";

//styles
import styles from "../../../../css/ParenteralModal.module.css";

function ParenteralModalNutrient({ data, inputs, handleChange }) {
  const nutrient = nutrientCalc(data);

  const kgWt = data.wt ? `${Math.round(data.wt / 2.2)} kg` : "";
  const calorieNeeds = nutrient.nutrientCalories
    ? nutrient.nutrientCalories
    : "No Calorie Needs Inputed";
  const proteinNeeds = nutrient.nutrientProtein
    ? nutrient.nutrientProtein
    : "No Protein Needs Inputed";
  const fluidNeeds = nutrient.nutrientFluids
    ? nutrient.nutrientFluids
    : "No Fluid Needs Inputed";

  return (
    <div className={styles.parenteral_modal_nutrient_container}>
      <h4 style={{ marginBottom: "2px", marginTop: "0px" }}>Nutrient Needs</h4>
      <label>
        Wt in kg:
        <span style={{ marginLeft: "5px" }}>{kgWt}</span>
      </label>
      <label>
        {" "}
        Calorie Needs:
        <span style={{ marginLeft: "5px" }}>{calorieNeeds}</span>
      </label>
      <label>
        {" "}
        Protein Needs:
        <span style={{ marginLeft: "5px" }}>{proteinNeeds}</span>
      </label>
      <label>
        {" "}
        Fluid Needs:
        <span style={{ marginLeft: "5px" }}>{fluidNeeds}</span>
      </label>
      <div className={styles.parenteral_modal_nutrient_question}>
        <label>
          Currently on PN?
          <input
            type="checkbox"
            checked={inputs.currPnBoolean}
            name="currPnBoolean"
            onChange={handleChange}
          />
        </label>
        <span>Yes/No</span>
      </div>
      <div className={styles.parenteral_modal_nutrient_question}>
        <label>
          Recommendations for PN?
          <input
            type="checkbox"
            checked={inputs.recPnBoolean}
            name="recPnBoolean"
            onChange={handleChange}
          />
        </label>
        <span>Yes/No</span>
      </div>
    </div>
  );
}

export default ParenteralModalNutrient;
