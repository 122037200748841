//redux hooks
import {
  useGetUsersQuery,
  useDeletUserMutation,
} from "../../../app/api/usersApiSlice";

//styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const User = ({ userId, handleEdit }) => {
  const { user } = useGetUsersQuery("userList", {
    selectFromResult: ({ data }) => ({
      user: data?.entities[userId],
    }),
  });

  const [userDelete] = useDeletUserMutation();

  const handleDelete = async (id) => {
    await userDelete({ id });
  };

  if (user) {
    return (
      <tr key={user._id}>
        <td>{user.username}</td>
        <td>{user.email}</td>
        <td>{user.roles}</td>
        <td>{user.active ? "yes" : "no"}</td>
        <td>{user.enable2fa ? "Enabled" : "Not Enabled"}</td>
        <td>
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => handleEdit(user._id)}
            style={{
              color: "#6e6d70",
              cursor: "pointer",
            }}
          />
        </td>
        <td>
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => handleDelete(user._id)}
            style={{
              color: "#6e6d70",
              cursor: "pointer",
            }}
          />
        </td>
      </tr>
    );
  } else return null;
};

export default User;
