import { useState, useRef } from "react";

import { tfs } from "../../../../utils/staticData";

import { useUpdateItemMutation } from "../../../../app/api/listApiSlice";

import EnteralModalNutrient from "./EnteralModalNutrient";
import EnteralModalColumnOne from "./EnteralModalColumnOne";
import EnteralModalColumnTwo from "./EnteralModalColumnTwo";
import EnteralModalColumnThree from "./EnteralModalColumnThree";
import SaveButton from "../../../../components/buttons/SaveButton";

//styles
import styles from "../../../../css/EnteralModal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const EnteralModal = ({ data, closeModal }) => {
  const [tfDescription, setTfDescription] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isSaveVisible, setSaveIsVisible] = useState(false);
  const [inputs, setInputs] = useState({
    id: data._id,
    currTFmethod: data.currTFmethod,
    recTFmethod: data.recTFmethod,
    tfType: data.tfType,
    tfRate: data.tfRate,
    recTFRate: data.recTFRate,
    tfPerDay: data.tfPerDay,
    recTFperDay: data.recTFperDay,
    recTubeFeed: data.recTubeFeed,
    selectedOption: data.selectedOption,
    freeFlush: data.freeFlush,
    recFreeFlush: data.recFreeFlush,
    medFlush: data.medFlush,
    bolusFlush: data.bolusFlush,
    recBolusFlush: data.recBolusFlush,
    enteralRecs: data.enteralRecs,
  });

  const ref = useRef("");

  const [updateItem, { isError, error }] = useUpdateItemMutation();

  const foundTF = tfs.find((item) => item.id === tfDescription);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleTFdescription = (id) => {
    setTfDescription(id);
  };

  const handleClearTFDescription = () => {
    setTfDescription("");
  };

  const handleChecked = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    setInputs((prevState) => ({ ...prevState, [name]: checked }));
  };

  const handleTfCheck = (e, id) => {
    const { checked } = e.target;

    if (checked) {
      setInputs((prevState) => ({ ...prevState, selectedOption: id }));

      const tubeFeeding = tfs.find((item) => item.id === id);

      if (inputs.recTFmethod === "bolus" && inputs.recTFperDay === 1) {
        setInputs((prevState) => ({
          ...prevState,
          recTubeFeed: `Recommendation: ${tubeFeeding.name} @ ${inputs.recTFRate} mL Daily`,
        }));
      }

      if (inputs.recTFmethod === "bolus" && inputs.recTFperDay === 2) {
        setInputs((prevState) => ({
          ...prevState,
          recTubeFeed: `Recommendation: ${tubeFeeding.name} @ ${inputs.recTFRate} mL BID`,
        }));
      }

      if (inputs.recTFmethod === "bolus" && inputs.recTFperDay === 3) {
        setInputs((prevState) => ({
          ...prevState,
          recTubeFeed: `Recommendation: ${tubeFeeding.name} @ ${inputs.recTFRate} mL TID`,
        }));
      }

      if (inputs.recTFmethod === "bolus" && inputs.recTFperDay === 4) {
        setInputs((prevState) => ({
          ...prevState,
          recTubeFeed: `Recommendation: ${tubeFeeding.name} @ ${inputs.recTFRate} mL QID`,
        }));
      }

      if (inputs.recTFmethod === "bolus" && Number(inputs.recTFperDay) > 4) {
        setInputs((prevState) => ({
          ...prevState,
          recTubeFeed: `Recommendation: ${tubeFeeding.name} @ ${inputs.recTFRate} mL x ${inputs.recTFperDay} per day`,
        }));
      }

      if (inputs.recTFmethod === "continuous") {
        setInputs((prevState) => ({
          ...prevState,
          recTubeFeed: `Recommendation: ${tubeFeeding.name} @ ${inputs.recTFRate} mL/hr`,
        }));
      }

      if (inputs.recTFmethod === "cyclic") {
        setInputs((prevState) => ({
          ...prevState,
          recTubeFeed: `Recommendation: ${tubeFeeding.name} @ ${inputs.recTFRate} mL/hr x ${inputs.recTFperDay} hrs`,
        }));
      }
    }
    if (!checked) {
      setInputs((prevState) => ({ ...prevState, selectedOption: "" }));
      setInputs((prevState) => ({
        ...prevState,
        recTubeFeed: "",
      }));
    }
  };

  const handleNestedChange = (e) => {
    const { name, value } = e.target;
    const [parentKey, childKey] = name.split(".");

    switch (parentKey) {
      case "freeFlush":
        setInputs({
          ...inputs,
          freeFlush: { ...inputs.freeFlush, [childKey]: value },
        });
        break;
      case "recFreeFlush":
        setInputs({
          ...inputs,
          recFreeFlush: { ...inputs.recFreeFlush, [childKey]: value },
        });
        break;
      case "medFlush":
        setInputs({
          ...inputs,
          medFlush: { ...inputs.medFlush, [childKey]: value },
        });
        break;
      case "bolusFlush":
        setInputs({
          ...inputs,
          bolusFlush: { ...inputs.bolusFlush, [childKey]: value },
        });
        break;
      case "recBolusFlush":
        setInputs({
          ...inputs,
          recBolusFlush: { ...inputs.recBolusFlush, [childKey]: value },
        });
        break;
      default:
        console.log(`${parentKey} cannot be found`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsVisible(true);

    await updateItem(inputs);

    setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    setTimeout(() => {
      setSaveIsVisible(true);
    }, 3000);

    setTimeout(() => {
      setSaveIsVisible(false);
    }, 5000);
  };

  return (
    <>
      <div className={styles.enteral_modal_top_close}>
        <FontAwesomeIcon
          icon={faXmark}
          size="lg"
          onClick={() => closeModal("Enteral")}
          style={{
            paddingRight: "10px",
            paddingBottom: "10px",
            cursor: "pointer",
          }}
        />
      </div>
      <div style={{ display: "flex" }}>
        <EnteralModalNutrient data={data} inputs={inputs} />
        <EnteralModalColumnOne
          data={data}
          inputs={inputs}
          handleChange={handleChange}
          handleNestedChange={handleNestedChange}
          handleChecked={handleChecked}
        />
        <EnteralModalColumnTwo
          data={data}
          inputs={inputs}
          ref={ref}
          handleChange={handleChange}
          handleNestedChange={handleNestedChange}
          handleTfCheck={handleTfCheck}
          handleTFdescription={handleTFdescription}
        />
        <EnteralModalColumnThree
          tfDescription={tfDescription}
          foundTF={foundTF}
          handleClearTFDescription={handleClearTFDescription}
        />
      </div>
      <SaveButton
        title="Save"
        isVisible={isVisible}
        isSaveVisible={isSaveVisible}
        isError={isError}
        error={error}
        event={handleSubmit}
      />
    </>
  );
};

export default EnteralModal;
