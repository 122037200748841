import { tfs } from "./staticData";

import { nutrientCalc } from "./helperFunctions";

export const freeWaterFunction = (inputs) => {
  const freeWaterFlush =
    Number(inputs.freeFlush.freeRate) * Number(inputs.freeFlush.freeTimes);
  const medWaterFlush =
    Number(inputs.medFlush.medRate) * Number(inputs.medFlush.medTimes);
  const bolusWaterFlush =
    Number(inputs.bolusFlush.bolusRate) * Number(inputs.bolusFlush.bolusTimes);

  const recFreeWaterFlush =
    Number(inputs.recFreeFlush?.freeRate) *
    Number(inputs.recFreeFlush?.freeTimes);
  const recBolusWaterFlush =
    Number(inputs.recBolusFlush?.bolusRate) *
    Number(inputs.recBolusFlush?.bolusTimes);

  return {
    freeWaterFlush,
    medWaterFlush,
    bolusWaterFlush,
    recFreeWaterFlush,
    recBolusWaterFlush,
  };
};

export const waterFlushFunction = (inputs) => {
  const water = freeWaterFunction(inputs);

  const recWaterFlushNames = [];
  const waterFlushNames = [];

  if (water.recFreeWaterFlush > 0) recWaterFlushNames.push("Free Water");
  if (water.recBolusWaterFlush > 0) recWaterFlushNames.push("Bolus");
  if (water.bolusWaterFlush > 0) waterFlushNames.push("Bolus");
  if (water.freeWaterFlush > 0) waterFlushNames.push("Free Water");
  if (water.medWaterFlush > 0) {
    recWaterFlushNames.push("Meds");
    waterFlushNames.push("Meds");
  }

  return { recWaterFlushNames, waterFlushNames };
};

export const tfCalculationFunction = (inputs, data) => {
  const tfPerDayCorrection =
    inputs.currTFmethod !== "continuous" ? Number(inputs.tfPerDay) : 24;
  const rectfPerDayCorrection =
    inputs.recTFmethod !== "continuous" ? Number(inputs.recTFperDay) : 24;

  const foundCurrentTF = tfs.find((item) => item.name === inputs.tfType);
  const foundRecTF = tfs.find((item) => item.id === inputs.selectedOption);
  const recTFvolume = Number(inputs.recTFRate) * rectfPerDayCorrection;
  const tfVolume = Number(inputs.tfRate) * tfPerDayCorrection;

  const nutrient = nutrientCalc(data);

  const water = freeWaterFunction(inputs);

  const calories = foundCurrentTF ? foundCurrentTF.calories * tfVolume : "";
  const recCalories = foundRecTF ? foundRecTF.calories * recTFvolume : "";
  const protein = foundCurrentTF ? foundCurrentTF.protein * tfVolume : "";
  const recProtein = foundRecTF ? foundRecTF.protein * recTFvolume : "";
  const fluid = foundCurrentTF ? foundCurrentTF.fluid * tfVolume : "";
  const recFluid = foundRecTF ? foundRecTF.fluid * recTFvolume : "";

  const total = [
    fluid,
    water.freeWaterFlush,
    water.medWaterFlush,
    water.bolusWaterFlush,
  ];

  const recTotalFluid = [
    recFluid,
    water.recFreeWaterFlush,
    water.medWaterFlush,
    water.recBolusWaterFlush,
  ];

  const totalFluidVolume = total.reduce((a, b) => a + b, 0);
  const recTotalFluidVolume = recTotalFluid.reduce((a, b) => a + b, 0);

  const tfPercentCalc = calorieCalc(calories, nutrient);
  const recTfPercentCalc = calorieCalc(recCalories, nutrient);
  const tfPercentPro = proteinCalc(protein, nutrient);
  const recTfPercentPro = proteinCalc(recProtein, nutrient);
  const tfPercentFluid = fluidCalc(totalFluidVolume, nutrient);
  const recTfPercentFluid = fluidCalc(recTotalFluidVolume, nutrient);

  return {
    tfVolume,
    recTFvolume,
    calories,
    recCalories,
    protein,
    recProtein,
    fluid,
    recFluid,
    totalFluidVolume,
    recTotalFluidVolume,
    tfPercentCalc,
    recTfPercentCalc,
    tfPercentPro,
    recTfPercentPro,
    tfPercentFluid,
    recTfPercentFluid,
  };
};

export function calorieCalc(calories, nutrient) {
  const calneedslow = Number(nutrient.calLow);
  const calneedshigh = Number(nutrient.calHigh);

  if (calories < calneedslow) {
    return (calories / calneedslow) * 100;
  } else if (calories > calneedshigh) {
    return (calories / calneedshigh) * 100;
  } else if (
    calneedslow &&
    calories >= calneedslow &&
    calories <= calneedshigh
  ) {
    return 0;
  } else {
    return "";
  }
}

export function proteinCalc(protein, nutrient) {
  const proneedslow = Number(nutrient.proLow);
  const proneedshigh = Number(nutrient.proHigh);

  if (protein < proneedslow) {
    return (protein / proneedslow) * 100;
  } else if (protein > proneedshigh) {
    return (protein / proneedshigh) * 100;
  } else if (proneedslow && protein >= proneedslow && protein <= proneedshigh) {
    return 0;
  } else {
    return "";
  }
}

export function fluidCalc(totalFluidVolume, nutrient) {
  const fluidneedslow = Number(nutrient.fluidLow);
  const fluidneedshigh = Number(nutrient.fluidHigh);

  if (totalFluidVolume < fluidneedslow) {
    return (totalFluidVolume / fluidneedslow) * 100;
  } else if (totalFluidVolume > fluidneedshigh) {
    return (totalFluidVolume / fluidneedshigh) * 100;
  } else if (
    fluidneedslow &&
    totalFluidVolume >= fluidneedslow &&
    totalFluidVolume <= fluidneedshigh
  ) {
    return 0;
  } else {
    return "";
  }
}

export function tfOrderWriter(tf, method, times, rate) {
  if (method === "bolus" && times === 1) {
    return `${tf} @ ${rate} mL Daily`;
  }
  if (method === "bolus" && times === 2) {
    return `${tf} @ ${rate} mL BID`;
  }
  if (method === "bolus" && times === 3) {
    return `${tf} @ ${rate} mL TID`;
  }
  if (method === "bolus" && times === 4) {
    return `${tf} @ ${rate} mL QID`;
  }
  if (method === "bolus" && times > 4) {
    return `${tf} @ ${rate} mL x ${times} per day`;
  }
  if (method === "continuous") {
    return `${tf} @ ${rate} mL/hr`;
  }
  if (method === "cyclic") {
    return `${tf} @ ${rate} mL/hr x ${times} hrs`;
  }
}
