import styles from "./css/Button.module.css";

const SaveButton = ({
  title,
  isVisible,
  isSaveVisible,
  isError,
  error,
  event,
}) => {
  return (
    <div className={styles.save_button_container}>
      <button
        type="button"
        disabled={isVisible || isSaveVisible}
        className={styles.button}
        onClick={event}>
        {title}
      </button>
      {isVisible && <p className={styles.assess_save}>Saving...</p>}
      {isSaveVisible && <p className={styles.assess_save}>Saved</p>}
      {isError && (
        <p className={styles.upper_assess_save}>{error?.data?.message}</p>
      )}
    </div>
  );
};

export default SaveButton;
