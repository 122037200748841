import styles from "../../../../css/DataEntry.module.css";
import globalStyles from "../../../../index.module.css";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  caaWtChangeProgressNote,
  getTextureChecker,
  gettheraDietChecker,
  mdsCAAgeneratorBMI,
} from "../../../../utils/helperFunctions.js";
import {
  TEXTURECHECKER,
  THERADIETCHECKER,
} from "../../../../utils/staticData.js";
import { useUpdateItemMutation } from "../../../../app/api/listApiSlice.js";
import { useUpdateLongTermItemMutation } from "../../../../app/api/longTermApiSlice.js";
import { v4 as uuid } from "uuid";

import DataEntryLabs from "./DataEntryLabs.jsx";
import NutritionDiagnosis from "./NutritionDiagnosis";
import NutritionRecs from "./NutritionRecs.jsx";
import StandardButton from "../../../../components/buttons/StandardButton.js";
import SaveButton from "../../../../components/buttons/SaveButton.js";

export default function DataEntryForm({ data }) {
  const [count, setCount] = useState(2);
  const [recCount, setRecCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isSaveVisible, setSaveIsVisible] = useState(false);
  const [inputs, setInputs] = useState({
    id: data._id,
    prevSummary: data.prevSummary,
    currSummary: data.currSummary,
    nutritionDiagnosis: data.nutritionDiagnosis,
    nutritionRecs: data.nutritionRecs,
  });

  const [updateItem, { isError, error }] = useUpdateItemMutation();
  const [updateLtItem] = useUpdateLongTermItemMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  ///handle Nested object changes///
  const handleNestedChange = (e, id) => {
    const { name, value } = e.target;
    const [parentKey, childKey] = name.split(".");

    switch (parentKey) {
      case "nutritionDiagnosis":
        const mappedNutritionDiagnosisInputs = inputs.nutritionDiagnosis.map(
          (item) => (item.id === id ? { ...item, [childKey]: value } : item)
        );
        setInputs({ ...inputs, [parentKey]: mappedNutritionDiagnosisInputs });
        break;
      case "nutritionRecs":
        const mappedNutritionRecsInputs = inputs.nutritionRecs.map((item) =>
          item.id === id ? { ...item, [childKey]: value } : item
        );
        setInputs({ ...inputs, [parentKey]: mappedNutritionRecsInputs });
        break;
      case "caaWt":
        const mappedcaaWtInputs = inputs.caaWt.map((item) =>
          item.wtid === id ? { ...item, [childKey]: value } : item
        );
        setInputs({ ...inputs, [parentKey]: mappedcaaWtInputs });
        break;
      default:
        console.log(`${parentKey} cannot be found`);
    }
  };

  // add additional input field
  const handleAddition = (name) => {
    switch (name) {
      case "nutritionDiagnosis":
        if (count <= 3) {
          setCount((prev) => prev + 1);
          setInputs({
            ...inputs,
            nutritionDiagnosis: [
              ...inputs.nutritionDiagnosis,
              {
                id: uuid(),
                num: count,
                problem: "",
                etiology: "",
                symptom: "",
              },
            ],
          });
        } else {
          return;
        }
        break;
      case "nutritionRecs":
        if (recCount <= 3) {
          setRecCount((prev) => prev + 1);
          setInputs({
            ...inputs,
            nutritionRecs: [
              ...inputs.nutritionRecs,
              {
                id: uuid(),
                num: recCount,
                value: "",
              },
            ],
          });
        } else {
          return;
        }
        break;
      default:
        console.log(`no ${name} found`);
    }
  };

  const handleRemove = (name, id) => {
    switch (name) {
      case "nutritionDiagnosis":
        setCount((prev) => prev - 1);
        const mappedNutritionDiagnosis = inputs.nutritionDiagnosis.filter(
          (item) => item.id !== id
        );
        setInputs({ ...inputs, nutritionDiagnosis: mappedNutritionDiagnosis });
        break;
      case "nutritionRecs":
        setRecCount((prev) => prev - 1);
        const mappedNutritionRecs = inputs.nutritionRecs.filter(
          (item) => item.id !== id
        );
        setInputs({ ...inputs, nutritionRecs: mappedNutritionRecs });
        break;
      default:
        console.log(`no ${name} found`);
    }
  };

  /// send state changes to database
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsVisible(true);

    await updateItem(inputs);

    setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    setTimeout(() => {
      setSaveIsVisible(true);
    }, 3000);

    setTimeout(() => {
      setSaveIsVisible(false);
    }, 5000);

    if (data.ltentry) {
      await updateLtItem({
        firstName: data.firstName,
        lastName: data.lastName,
        age: data.age,
        nutritionDiagnosis: inputs.nutritionDiagnosis,
        prevSummary: inputs.currSummary,
      });
    }
  };

  const theradiet = `On ${data.theraDiet} secondary to ${gettheraDietChecker(
    data
  )}`;
  const texture = `On ${getTextureChecker(data)}`;

  const labLength = data.labs.filter((l) => l.value !== "");

  const dietChecker = THERADIETCHECKER.some(
    (diet) => diet.toLowerCase() === data.theraDiet.toLowerCase()
  );

  const textureChecker = TEXTURECHECKER.some(
    (texture) => texture.toLowerCase() === data.texture.toLowerCase()
  );

  let caaNote;

  if (
    data.assessType === "Admission" ||
    data.assessType === "Readmission" ||
    data.assessType === "Significant Change"
  ) {
    caaNote = (
      <div className={styles.data_entry_caa_info}>
        <h4 className={styles.data_entry_title}>CAA Dx:</h4>
        {mdsCAAgeneratorBMI(data) && (
          <span>BMI: {mdsCAAgeneratorBMI(data)}</span>
        )}
        {!dietChecker && data.theraDiet && (
          <span>
            Diet: On {data.theraDiet} secondary to {gettheraDietChecker(data)}.
          </span>
        )}
        {!textureChecker && data.texture && (
          <span>Texture: On {getTextureChecker(data)}.</span>
        )}
        {data.ivfluids && <span>IV Fluids: {data.ivfluids}</span>}
        {caaWtChangeProgressNote(data).map((item, i) => (
          <ul key={i} className={styles.data_entry_caaWt}>
            <li>
              {item.notedchange} secondary to {item.reason}
            </li>
          </ul>
        ))}
      </div>
    );
  } else {
    caaNote = <span></span>;
  }

  return (
    <div className={styles.data_entry_form}>
      {!dietChecker && data.theraDiet ? (
        <>
          <span className={globalStyles.global_font_format}>
            <strong>Therapeutic diet: </strong>
          </span>
          <div className={styles.data_entry_div}>
            <span className={globalStyles.global_font_format}>{theradiet}</span>
            <CopyToClipboard text={theradiet} onCopy={(text) => text}>
              <span className={styles.button}>Copy</span>
            </CopyToClipboard>
          </div>
        </>
      ) : (
        <span></span>
      )}
      {!textureChecker && data.texture ? (
        <>
          <span className={globalStyles.global_font_format}>
            <strong>Texture modification: </strong>
          </span>
          <div className={styles.data_entry_div}>
            <span className={globalStyles.global_font_format}>{texture}</span>
            <CopyToClipboard text={texture} onCopy={(text) => text}>
              <span className={styles.button}>Copy</span>
            </CopyToClipboard>
          </div>
        </>
      ) : (
        <span></span>
      )}
      {labLength.length > 0 && (
        <h4 className={styles.data_entry_title}>Labs</h4>
      )}
      <div className={styles.data_entry_wrapped_labs}>
        {data.labs.map((lab) => (
          <DataEntryLabs id={lab.id} lab={lab} key={lab.id} />
        ))}
      </div>
      <div className={styles.data_entry_summary_container}>
        <h4 className={styles.data_entry_title}>Previous Summary</h4>
        <textarea
          className={styles.data_entry_summary}
          name="prevSummary"
          disabled={data.prevSummary}
          value={inputs.prevSummary}
          onChange={handleChange}></textarea>
        <h4 className={styles.data_entry_title}>Current Summary</h4>
        <textarea
          className={styles.data_entry_summary}
          name="currSummary"
          value={inputs.currSummary}
          onChange={handleChange}></textarea>
      </div>
      {caaNote}
      <div>
        <div className={styles.data_entry_nutrition_diagnosis_title}>
          <h4 className={styles.data_entry_title}>Recommendations</h4>
          <StandardButton
            event={handleAddition}
            name="nutritionRecs"
            title="Add Recommendation"
          />
        </div>
        {inputs.nutritionRecs.map((item, i) => (
          <NutritionRecs
            index={i}
            key={item.id}
            item={item}
            handleNestedChange={handleNestedChange}
            handleRemove={handleRemove}
          />
        ))}
      </div>
      <div>
        <div className={styles.data_entry_nutrition_diagnosis_title}>
          <h4 className={styles.data_entry_title}>Nutrition Diagnosis</h4>{" "}
          <StandardButton
            event={handleAddition}
            name="nutritionDiagnosis"
            title="Add New Diagnosis"
          />
        </div>
        {inputs.nutritionDiagnosis.map((item) => (
          <NutritionDiagnosis
            key={item.id}
            item={item}
            handleNestedchange={handleNestedChange}
            handleRemove={handleRemove}
          />
        ))}
      </div>
      <div className={styles.save_button_bar}>
        <SaveButton
          title="Save"
          isVisible={isVisible}
          isSaveVisible={isSaveVisible}
          isError={isError}
          error={error}
          event={handleSubmit}
        />
      </div>
    </div>
  );
}
