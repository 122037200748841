import {
  waterFlushFunction,
  tfCalculationFunction,
} from "../../../../utils/enteralHelperFunctions";

import styles from "../../../../css/EnteralModal.module.css";

const EnteralModalNutrient = ({ inputs, data }) => {
  const calc = tfCalculationFunction(inputs, data);
  const waterNames = waterFlushFunction(inputs);

  return (
    <div className={styles.enteral_modal_nutrients}>
      <h3 style={{ margin: "0px", textDecoration: "underline" }}>
        Current Nutrient Provisions
      </h3>
      <div className={styles.enteral_modal_column_one_div}>
        <span style={{ fontSize: "14px" }}>Total Calories:</span>
      </div>
      <ul>
        <li
          className={
            styles.enteral_modal_nutrients_li
          }>{`${calc.calories} kcal/volume`}</li>
        <li className={styles.enteral_modal_nutrients_li}>
          (
          {calc.tfPercentCalc > 0
            ? `${Math.round(calc.tfPercentCalc)}% of Calorie Needs`
            : "Within Range"}
          )
        </li>
      </ul>
      <div className={styles.enteral_modal_column_one_div}>
        <span style={{ fontSize: "14px" }}>Total Protein: </span>
      </div>
      <ul>
        <li className={styles.enteral_modal_nutrients_li}>
          {`${calc.protein && calc.protein.toFixed(1)} gm/volume`}
        </li>
        <li className={styles.enteral_modal_nutrients_li}>
          (
          {calc.tfPercentPro > 0
            ? `${Math.round(calc.tfPercentPro)}% of Protein Needs`
            : "Within Range"}
          )
        </li>
      </ul>
      <div className={styles.enteral_modal_column_one_div}>
        <span style={{ fontSize: "14px" }}>Total Tube Feeding Fluid: </span>
      </div>
      <ul>
        <li className={styles.enteral_modal_nutrients_li}>
          {`${Math.round(calc.fluid)} fluid (mL)/volume`}
        </li>
      </ul>
      <div className={styles.enteral_modal_column_one_div}>
        <span style={{ fontSize: "14px" }}>Total Fluid Volume: </span>
        <ul>
          <li className={styles.enteral_modal_nutrients_li}>
            {`${Math.round(calc.totalFluidVolume)} fluid (mL)/volume ${
              waterNames.waterFlushNames &&
              `(${waterNames.waterFlushNames.join(" + ")})`
            }`}
          </li>
          <li className={styles.enteral_modal_nutrients_li}>
            (
            {calc.tfPercentFluid > 0
              ? `${Math.round(calc.tfPercentFluid)}% of Fluid Needs`
              : "Within Range"}
            )
          </li>
        </ul>
      </div>
      <h3 style={{ marginBottom: "0px", textDecoration: "underline" }}>
        Recommendation Nutrient Provisions
      </h3>
      <div className={styles.enteral_modal_column_one_div}>
        <span style={{ fontSize: "14px" }}>Total Calories:</span>
      </div>
      <ul>
        {calc.recCalories && (
          <>
            <li
              className={
                styles.enteral_modal_nutrients_li
              }>{`${calc.recCalories} kcal/volume`}</li>
            <li className={styles.enteral_modal_nutrients_li}>
              ({`${Math.round(calc.recTfPercentCalc)}% of Calorie Needs`})
            </li>
          </>
        )}
      </ul>
      <div className={styles.enteral_modal_column_one_div}>
        <span style={{ fontSize: "14px" }}>Total Protein: </span>
      </div>
      <ul>
        {calc.recProtein && (
          <>
            <li className={styles.enteral_modal_nutrients_li}>
              {`${calc.recProtein.toFixed(1)} gm/volume`}
            </li>
            <li className={styles.enteral_modal_nutrients_li}>
              ({`${Math.round(calc.recTfPercentPro)}% of Protein Needs`})
            </li>
          </>
        )}
      </ul>
      <div className={styles.enteral_modal_column_one_div}>
        <span style={{ fontSize: "14px" }}>Total Tube Feeding Fluid: </span>
      </div>
      <ul>
        {calc.recFluid > 0 && (
          <li className={styles.enteral_modal_nutrients_li}>
            {`${Math.round(calc.recFluid)} fluid (mL)/volume`}
          </li>
        )}
      </ul>
      <div className={styles.enteral_modal_column_one_div}>
        <span style={{ fontSize: "14px" }}>Total Fluid Volume: </span>
        <ul>
          {calc.recFluid > 0 && (
            <>
              <li className={styles.enteral_modal_nutrients_li}>
                {`${Math.round(calc.recTotalFluidVolume)} fluid (mL)/volume ${
                  waterNames.recWaterFlushNames &&
                  `(${waterNames.recWaterFlushNames.join(" + ")})`
                }`}
              </li>
              <li className={styles.enteral_modal_nutrients_li}>
                (
                {calc.tfPercentFluid > 0
                  ? `${Math.round(calc.recTfPercentFluid)}% of Fluid Needs`
                  : "Within Range"}
                )
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default EnteralModalNutrient;
