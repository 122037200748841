import { useEffect, useState } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleDeviceDetection = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const device = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(
        userAgent
      );

      if (device) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleDeviceDetection();
    window.addEventListener("resize", handleDeviceDetection);
    return () => {
      window.removeEventListener("resize", handleDeviceDetection);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
