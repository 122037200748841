import { ageCalc, getBMI, getBmiType } from "./helperFunctions";

import {
  malnutritionRiskFunction,
  nutritionRecsFunction,
} from "./summaryHelperFunctions";

import {
  hospitalDxProgNote,
  dietPTA,
  supplementSplitter,
  foodProgressNote,
  textAreaDivider,
  labProgNote,
  ubwRangeCheckerProgNote,
  wtChangeProgressNote,
  nutNeedsProgNote,
  nutritionDxProgNote,
  nutritionRxProgNote,
  caaCreator,
  enteralProgressNote,
  enteralRecProgressNote,
} from "./progressNoteHelperFunctions";

export const bwtProgressTemplate = (data) => {
  const dateOfBirth = data.age;
  const allergy = {
    type: data.foodAllergy.map((item) => item.allergyname).join("/"),
    name: "NKFA",
  };
  const intolerance = {
    type: data.foodIntolerance.map((item) => item.intolerancename).join("/"),
    name: "NKFI",
  };
  const pmhx = { type: data.pmhx, name: "PAST MEDICAL HISTORY" };
  const meds = { type: data.meds, name: "NUTRITION RELATED MEDICATIONS" };
  const wtKg = data.wt / 2.2;
  const malnutritionRiskScreen = malnutritionRiskFunction(data);

  const progressNoteTemplate = `
      <p>
      Assessment Type: ${data.assessType} Nutrition Note<br>
      ${hospitalDxProgNote(data) && hospitalDxProgNote(data)}
      ${data.clientType}: ${data.lastName}, ${data.firstName}<br>
      Date of Birth: ${
        dateOfBirth
          ? `${new Date(dateOfBirth).toLocaleDateString("en")} (${ageCalc(
              dateOfBirth
            )} years old)<br>`
          : "Not a Valid Date<br>"
      }
      Room Number: ${data.room}<br>
      ${
        data.theraDiet &&
        data.texture &&
        data.liquids &&
        `Diet: ${data.theraDiet} ${data.texture && `, ${data.texture}`} ${
          data.liquids && `, ${data.liquids}`
        }<br>`
      }
      ${
        data.prevAssessType &&
        `Last Assessment: ${
          data.prevAssessType
        } <br> Last Assessment Date: ${new Date(
          data.prevDate
        ).toLocaleDateString("en-US", { timeZone: "UTC" })}<br>`
      }
      ${
        data.assessType === "Quarterly" || data.assessType === "Annual"
          ? `&#10070 Date Diet Ordered: (${new Date(
              data.dietstartdate
            ).toLocaleDateString("en")}) &#10132 ${
              new Date(data.dietstartdate) > new Date(data.prevDate)
                ? "Changed"
                : "No Change"
            } from last Assessment<br>`
          : ""
      }
      ${dietPTA(data)}
      ${enteralProgressNote(data)}
      ${data.portionSize && `Portion Size: ${data.portionSize}<br>`}
      </p>
      ${supplementSplitter(data)}
      <p>
      Meal Intake: ${data.foodIntake}<br>
      ${supplementSplitter(data) && `Supplement Intake: ${data.supIntake}<br>`}
      Food Allergies: ${foodProgressNote(allergy)}<br>
      Food Intolerances: ${foodProgressNote(intolerance)}<br>
      Eating (Self Performance): ${data.eatSelfPerform}<br>
      Eating (Support Provided): ${data.eatSupportPerform}<br>
      Upper Dentition: ${data.upperDent}<br>
      Lower Dentition: ${data.lowerDent}<br>
      Adaptive Equipment: ${!data.adaptEquip ? "None" : data.adaptEquip}<br>
      Skin Integrity: ${data.skin}<br>
      ${
        data.currSummary &&
        `OTHER DIET HISTORY INFORMATION:<br>
      ${data.currSummary}<br>`
      }
      </p>
      ${textAreaDivider(pmhx)}
      ${textAreaDivider(meds)}
      ${labProgNote(data)}
      <p>
      ---------------------------<br>
      Current Wt(lbs): ${
        !data.wt ? "No weight at this time" : `${data.wt} lbs`
      }<br> 
      Current Wt(kg): ${
        !data.wt ? "unable to obtain without weight" : `${wtKg.toFixed(1)} kg`
      }<br>
      BMI: ${
        !data.wt
          ? "unable to obtain without weight"
          : `${getBMI(data.ht, data.wt).toFixed(2)} (${getBmiType(
              data.ht,
              data.wt
            )})`
      }<br>
      Height: ${data.ht} inches<br>
      ${ubwRangeCheckerProgNote(data)}
      ${wtChangeProgressNote(data)}
      ${nutNeedsProgNote(data)}
      ${nutritionDxProgNote(data)}
      ${nutritionRxProgNote(data)}
      ${
        malnutritionRiskScreen.total.num > 0
          ? `---------------------------<br>Malnutrition Risk Screen:<br>${malnutritionRiskScreen.malName}`
          : ""
      }<br>
      ${caaCreator(data).formatted ? caaCreator(data).formatted : ""}
      ${
        nutritionRecsFunction(data)
          ? `---------------------------<br>${nutritionRecsFunction(data)}`
          : ""
      }
      ${enteralRecProgressNote(data)}
      </p>
      <br>
  `;

  return progressNoteTemplate;
};
