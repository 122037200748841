import { CAL_VALUES } from "../../../../utils/staticData";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styles from "../../../../css/Assessment.module.css";
import { nutrientCalc } from "../../../../utils/helperFunctions";

const NutrientNeeds = ({ inputs, handleChange }) => {
  const calc = nutrientCalc(inputs);

  return (
    <div className={styles.form_cal_pro_needs}>
      <span>Calorie Needs:</span>
      <select
        name="calneedslow"
        value={inputs.calneedslow}
        onChange={handleChange}>
        {CAL_VALUES.calFluidValslow.map((cl, i) => (
          <option value={cl} key={i}>
            {cl}
          </option>
        ))}
      </select>
      <span className={styles.form_calorie_needs_center}>to</span>
      <select
        name="calneedshigh"
        value={inputs.calneedshigh}
        onChange={handleChange}>
        {CAL_VALUES.calFluidValshigh.map((cl, i) => (
          <option value={cl} key={i}>
            {cl}
          </option>
        ))}
      </select>
      {calc.nutrientCalories ? (
        <span className={styles.form_calculations_center}>
          {calc.nutrientCalories}
          <CopyToClipboard
            text={calc.nutrientCalories}
            onCopy={(text, result) => text}>
            <button type="button" style={{ marginLeft: "0.5rem" }}>
              Copy
            </button>
          </CopyToClipboard>
        </span>
      ) : (
        <span></span>
      )}

      <span>Protein Needs:</span>
      <select
        name="proneedslow"
        value={inputs.proneedslow}
        onChange={handleChange}>
        {CAL_VALUES.proteinValslow.map((pro, i) => (
          <option value={pro} key={i}>
            {pro}
          </option>
        ))}
      </select>
      <span className={styles.form_calorie_needs_center}>to</span>
      <select
        name="proneedshigh"
        value={inputs.proneedshigh}
        onChange={handleChange}>
        {CAL_VALUES.proteinvalshigh.map((pro, i) => (
          <option value={pro} key={i}>
            {pro}
          </option>
        ))}
      </select>
      {calc.nutrientProtein ? (
        <span className={styles.form_calculations_center}>
          {calc.nutrientProtein}
          <CopyToClipboard
            text={calc.nutrientProtein}
            onCopy={(text, result) => text}>
            <button type="button" style={{ marginLeft: "0.5rem" }}>
              Copy
            </button>
          </CopyToClipboard>
        </span>
      ) : (
        <span></span>
      )}
      <span>Fluid Needs:</span>
      <select
        name="fluidneedslow"
        value={inputs.fluidneedslow}
        onChange={handleChange}>
        {CAL_VALUES.calFluidValslow.map((cl, i) => (
          <option value={cl} key={i}>
            {cl}
          </option>
        ))}
      </select>
      <span className={styles.form_calorie_needs_center}>to</span>
      <select
        name="fluidneedshigh"
        value={inputs.fluidneedshigh}
        onChange={handleChange}>
        {CAL_VALUES.calFluidValshigh.map((cl, i) => (
          <option value={cl} key={i}>
            {cl}
          </option>
        ))}
      </select>
      {calc.nutrientFluids ? (
        <span className={styles.form_calculations_center}>
          {calc.nutrientFluids}
          <CopyToClipboard
            text={calc.nutrientFluids}
            onCopy={(text, result) => text}>
            <button type="button" style={{ marginLeft: "0.5rem" }}>
              Copy
            </button>
          </CopyToClipboard>
        </span>
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default NutrientNeeds;
