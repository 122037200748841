//react hooks
import { useState } from "react";

//redux hooks
import { useGetUsersQuery } from "../../../app/api/usersApiSlice";

//components
import EditUser from "./EditUser";
import User from "./User";

//stles
import styles from "../../../css/Settings.module.css";

const ViewAllUsers = () => {
  const [id, setId] = useState("");
  const [editToggle, setEditToggle] = useState(false);

  const { data: user, isSuccess } = useGetUsersQuery("usersList", {
    pollingInterval: 3000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const handleEdit = (id) => {
    setId(id);
    setEditToggle(true);
  };

  const handleEditClosed = () => {
    setEditToggle(false);
  };

  let content;
  if (isSuccess) {
    const { ids, entities } = user;

    const tableContent =
      ids?.length &&
      ids.map((userId) => (
        <User key={userId} userId={userId} handleEdit={handleEdit} />
      ));

    const filteredUser = entities[id];

    content = (
      <div className={styles.settings_outer_container}>
        <div className={styles.settings_inner_container}>
          <h4 className={styles.settings_view_all_title}>VIEW ALL USERS</h4>
          <table className={styles.view_all_users_table}>
            <tbody>
              <tr>
                <th>Username</th>
                <th>E-mail</th>
                <th>Roles</th>
                <th>Active Status</th>
                <th>2FA Status</th>
                <th></th>
              </tr>
            </tbody>
            <tbody>{tableContent}</tbody>
          </table>
        </div>
        {editToggle && (
          <div className={styles.settings_inner_container}>
            <EditUser user={filteredUser} handleEditClosed={handleEditClosed} />
          </div>
        )}
      </div>
    );
  }

  return content;
};

export default ViewAllUsers;
