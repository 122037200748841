import styles from "./css/Button.module.css";
const WhiteButton = ({ name, event, children }) => {
  return (
    <button name={name} className={styles.top_bar_button} onClick={event}>
      {name}
      {children}
    </button>
  );
};

export default WhiteButton;
