import { malnutritionRiskScreen, DIETS, KEYWORDS } from "./staticData";

//Age Calculation Function
export const ageCalc = (age) => {
  const diff_ms = Date.now() - new Date(age).getTime();
  const age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
};

//Equations function
export function equationCalculation(data, age, sex) {
  const wt = Number(data.wt);
  const ht = Number(data.ht);
  const actFactor = Number(data.actFactor);
  const injFactor = Number(data.injFactor);
  const wtKg = wt / 2.2;
  const htCm = ht * 2.54;

  let livEquation;
  let mfsEquation;

  const livMaleForm = 293 * wtKg ** 0.433 - 5.92 * age;
  const livFemaleForm = 248 * wtKg ** 0.4356 - 5.09 * age;
  const mfsMaleForm = 10 * wtKg + 6.25 * htCm - (5 * age + 5);
  const mfsFemaleForm = 10 * wtKg + 6.25 * htCm - 5 * age - 161;

  if (sex === "male") {
    if (actFactor > 0 && injFactor > 0) {
      livEquation = livMaleForm * actFactor * injFactor;
      mfsEquation = mfsMaleForm * actFactor * injFactor;
    } else if (actFactor > 0) {
      livEquation = livMaleForm * actFactor;
      mfsEquation = mfsMaleForm * actFactor;
    } else {
      livEquation = livMaleForm;
      mfsEquation = mfsMaleForm;
    }
  }

  if (sex === "female") {
    if (actFactor > 0 && injFactor > 0) {
      livEquation = livFemaleForm * actFactor * injFactor;
      mfsEquation = mfsFemaleForm * actFactor * injFactor;
    } else if (actFactor > 0) {
      livEquation = livFemaleForm * actFactor;
      mfsEquation = mfsFemaleForm * actFactor;
    } else {
      livEquation = livFemaleForm;
      mfsEquation = mfsFemaleForm;
    }
  }
  return { livEquation: livEquation, mfsEquation: mfsEquation };
}

//Nutrient Range Calc Function
export function nutrientCalc(data) {
  const ibw = setIBW(data.ht, data.sex);
  const kg = Number(data.wt) / 2.2;

  const calculation = (nutrient) => {
    if (data.wttype === "currentwt") {
      return kg * Number(nutrient);
    } else if (data.wttype === "ibwwt") {
      return (ibw / 2.2) * Number(nutrient);
    } else {
      return 0;
    }
  };
  const calLow = calculation(data.calneedslow);
  const calHigh = calculation(data.calneedshigh);
  const proLow = calculation(data.proneedslow);
  const proHigh = calculation(data.proneedshigh);
  const fluidLow = calculation(data.fluidneedslow);
  const fluidHigh = calculation(data.fluidneedshigh);

  const fluidLowOunces = fluidLow / 30;
  const fluidHighOunces = fluidHigh / 30;

  const nutrientCalories =
    calLow > 0 && `${Math.round(calLow)}-${Math.round(calHigh)} kcal/day`;

  const nutrientProtein =
    proLow > 0 && `${Math.round(proLow)} - ${Math.round(proHigh)} gm/day`;

  const nutrientFluids =
    fluidLow > 0 &&
    //prettier-ignore
    `${Math.round(fluidLow)} - ${Math.round(fluidHigh)} mL/day (${Math.round(fluidLowOunces)} - ${Math.round(fluidHighOunces)} fl oz)`;

  return {
    nutrientCalories: nutrientCalories,
    nutrientProtein: nutrientProtein,
    nutrientFluids: nutrientFluids,
    calLow,
    calHigh,
    proLow,
    proHigh,
    fluidLow,
    fluidHigh,
  };
}

//BMI function
export const getBMI = (ht, wt) => {
  const currHt = Number(ht);
  const currWt = Number(wt);

  if (currHt > 0 && currWt > 0) {
    return (703 * currWt) / currHt ** 2;
  } else {
    return 0;
  }
};

export const getBmiType = (ht, wt) => {
  const bmi = getBMI(ht, wt);

  if (bmi > 40) {
    return "Class III Obesity";
  } else if (bmi < 40 && bmi >= 31) {
    return "Class II Obesity";
  } else if (bmi >= 30 && bmi <= 31) {
    return "Class I Obesity";
  } else if (bmi > 25.95 && bmi <= 29.9) {
    return "Overweight";
  } else if (bmi >= 19.4 && bmi <= 25.9) {
    return "Normal Weight";
  } else if (bmi < 19.3 && bmi > 0) {
    return "Underweight";
  } else {
    return;
  }
};

//IBW function
export const setIBW = (ht, sex) => {
  if (ht > 40) {
    if (sex === "male") {
      return (ht - 60) * 6 + 106;
    } else if (sex === "female") {
      return (ht - 60) * 5 + 103;
    } else {
      return;
    }
  } else {
    return 0;
  }
};

//UBW
export const selectUBW = (currWt, ubwLow, ubwHigh) => {
  const numCurrWt = Number(currWt);
  const numUbwLow = Number(ubwLow);
  const numUbwHigh = Number(ubwHigh);

  if (numUbwLow && numCurrWt && numUbwHigh) {
    if (numCurrWt >= numUbwLow && numCurrWt <= numUbwHigh) {
      return 0;
    } else if (numCurrWt < numUbwLow) {
      return (numCurrWt / numUbwLow - 1) * 100;
    } else if (numCurrWt > numUbwHigh) {
      return (numCurrWt / numUbwHigh - 1) * 100;
    } else {
      return;
    }
  } else {
    return;
  }
};

//Wt Change Functions
export function lbsChangeCalculation(currwt, wtChange) {
  if (wtChange > 0) {
    return currwt - wtChange;
  } else {
    return 0;
  }
}

export function percentChangeCalc(currwt, wtChange) {
  if (wtChange > 0) {
    return ((currwt - wtChange) / currwt) * 100;
  } else {
    return 0;
  }
}

export function dateChange(currDate, date) {
  if (date) {
    const start = Date.parse(date);
    const end = Date.parse(currDate);
    return Math.floor((end - start) / (1000 * 60 * 60 * 24));
  } else {
    return 0;
  }
}

//Lab Checker
export function labCalc(lab, labValue, sex) {
  if (lab === "Alb") {
    if (labValue < 3.4 && labValue > 0) {
      return "low";
    } else if (labValue > 5.4) {
      return "high";
    } else if (labValue <= 5.4 && labValue >= 3.4) {
      return "";
    } else if (labValue === 0) {
      return "";
    }
  } else if (lab === "Na") {
    if (labValue <= 134 && labValue >= 126) {
      return "low";
    } else if (labValue >= 146 && labValue <= 159) {
      return "high";
    } else if (labValue <= 125 && labValue > 0) {
      return "very low";
    } else if (labValue >= 160) {
      return "very high";
    } else if (labValue <= 145 && labValue >= 135) {
      return "";
    } else if (labValue === 0) {
      return "";
    }
  } else if (lab === "K") {
    if (labValue <= 3.4 && labValue >= 2.6) {
      return "low";
    } else if (labValue >= 5.1 && labValue <= 5.9) {
      return "high";
    } else if (labValue <= 2.5 && labValue > 0) {
      return "very low";
    } else if (labValue >= 6.0) {
      return "very high";
    } else if (labValue <= 5.0 && labValue >= 3.5) {
      return "";
    } else if (labValue === 0) {
      return "";
    }
  } else if (lab === "BUN") {
    if (labValue <= 4.9 && labValue > 0) {
      return "low";
    } else if (labValue >= 21.0) {
      return "high";
    } else if (labValue <= 20.9 && labValue >= 5.0) {
      return "";
    } else if (labValue === 0) {
      return "";
    }
  } else if (lab === "Phos") {
    if (labValue <= 2.7 && labValue >= 1.1) {
      return "low";
    } else if (labValue >= 4.6 && labValue <= 8.9) {
      return "high";
    } else if (labValue <= 1.0 && labValue > 0) {
      return "very low";
    } else if (labValue >= 9.0) {
      return "very high";
    } else if (labValue <= 2.8 && labValue >= 4.5) {
      return "";
    } else if (labValue === 0) {
      return "";
    }
  } else if (lab === "Mag") {
    if (labValue <= 1.2 && labValue >= 1.1) {
      return "low";
    } else if (labValue >= 2.2 && labValue <= 8.9) {
      return "high";
    } else if (labValue <= 1.0 && labValue > 0) {
      return "very low";
    } else if (labValue >= 9.0) {
      return "very high";
    } else if (labValue <= 1.3 && labValue > 2.1) {
      return "";
    } else if (labValue === 0) {
      return "";
    }
  } else if (lab === "Creat") {
    if (sex === "male") {
      if (labValue <= 0.69 && labValue > 0) {
        return "low";
      } else if (labValue >= 1.4) {
        return "high";
      } else if (labValue <= 1.39 && labValue >= 0.7) {
        return "";
      }
    } else if (sex === "female") {
      if (labValue >= 1.2) {
        return "high";
      } else if (labValue <= 0.59 && labValue > 0) {
        return "low";
      } else if (labValue <= 1.19 && labValue >= 0.6) {
        return "";
      }
    }
  } else {
    return "";
  }
}

//Diet Checker
export function gettheraDietChecker(data) {
  const pmhxA = data.pmhx.split(", ").map((elm) => elm.trim());
  const theradiet = data.theraDiet;
  const therapeutdiet = [];
  const cadCount = [];

  //const dmcadKeyWords = [...KEYWORDS.cadKeyWords, ...KEYWORDS.dmKeyWords];

  pmhxA.forEach((p) => {
    if (DIETS.dmdiets.some((diet) => diet === theradiet)) {
      const dmpusheddiet = KEYWORDS.dmKeyWords.find(
        (elm) => elm.toLowerCase() === p.toLowerCase()
      );
      therapeutdiet.push(dmpusheddiet);
    }
    if (DIETS.cardiodiets.some((diet) => diet === theradiet)) {
      const liverpusheddiet = KEYWORDS.liverKeyWords.find(
        (elm) => elm.toLowerCase() === p.toLowerCase()
      );
      therapeutdiet.push(liverpusheddiet);
    }
    if (DIETS.cardiodiets.some((diet) => diet === theradiet)) {
      const cadpusheddiet = KEYWORDS.cadKeyWords.find(
        (elm) => elm.toLowerCase() === p.toLowerCase()
      );
      cadCount.push(cadpusheddiet);
    }
    if (DIETS.renaldiets.some((diet) => diet === theradiet)) {
      const renalpusheddiet = KEYWORDS.renalKeyWords.find(
        (elm) => elm.toLowerCase() === p.toLowerCase()
      );
      therapeutdiet.push(renalpusheddiet);
    }
    if (DIETS.glutendiets.some((diet) => diet === theradiet)) {
      const gutenpusheddiet = KEYWORDS.glKeyWords.find(
        (elm) => elm.toLowerCase() === p.toLowerCase()
      );
      therapeutdiet.push(gutenpusheddiet);
    }
  });

  const filteredCadCount = cadCount.filter((elm) => elm);

  if (filteredCadCount.length >= 3) {
    therapeutdiet.push("significant cardiovascular history");
  } else {
    filteredCadCount.forEach((elm) => {
      therapeutdiet.push(elm);
    });
  }

  let filteredtherapeuticdiet;
  if (therapeutdiet.length < 3) {
    filteredtherapeuticdiet = therapeutdiet.filter((elm) => elm).join(" and ");
  } else {
    filteredtherapeuticdiet = therapeutdiet.filter((elm) => elm).join(", ");
  }

  if (filteredtherapeuticdiet.length > 0) {
    return `PMHx of ${filteredtherapeuticdiet}`;
  } else if (
    data.theraDiet?.toLowerCase() !== "regular" ||
    data.theraDiet?.toLowerCase() !== "regular diet"
  ) {
    return `unkown etiology`;
  } else {
    return "";
  }
}

//Texture Checker
export function getTextureChecker(data) {
  const [textureProblem] = data.nutritionDiagnosis.filter(
    (item) =>
      item.problem === "Swallowing difficulty" ||
      item.problem === "Biting/Chewing (masticatory) difficulty"
  );
  const textureReason = textureProblem?.etiology;
  const dietTexture = data.texture?.toLowerCase();
  const liquidTexture = data.liquids?.toLowerCase();

  const normalTexture = ["regular", "regular texture"];
  const normalLiquids = ["regular", "regular liquids"];

  let textureContext;
  const textureCompare = normalTexture.some((food) => food === dietTexture);
  const liquidCompare = normalLiquids.some(
    (liquids) => liquids === liquidTexture
  );

  if (textureProblem && !textureCompare && !liquidCompare) {
    textureContext = `${dietTexture} and ${liquidTexture} secondary to ${textureReason}`;
  } else if (textureProblem && !textureCompare && liquidCompare) {
    textureContext = `${dietTexture} secondary to ${textureReason}`;
  } else if (textureProblem && textureCompare && !liquidCompare) {
    textureContext = `${liquidTexture} secondary to ${textureReason}`;
  } else if (dietTexture === "finger foods") {
    textureContext = `${dietTexture} secondary to limited ability to utilize utensils`;
  } else if (!textureProblem && (!textureCompare || !liquidCompare)) {
    textureContext = `texture modification secondary to unknown etiology`;
  } else if (textureCompare && liquidCompare) {
    textureContext = "";
  }

  return textureContext;
}

//Vitamin/Mineral Parser
export function vitaminRx(data) {
  const micronutrients = data.micronutrients;
  const addedMicronutrient = data.micronutrientInputs.map((item) => item.value);

  micronutrients.forEach((e) => {
    if (e.checked) {
      addedMicronutrient.push(e.name);
    }
  });

  return addedMicronutrient.filter((elm) => elm).join("/");
}

//Supplement Parser
export function supplementRx(data) {
  const supplements = data.supplementList;

  const supplementList = [];

  supplements.forEach((s) => {
    const date = new Date(s.supdate).toLocaleDateString();
    if (s.supname && date) {
      supplementList.push({
        name: s.supname,
        total: `${s.supname} started => ${date}`,
      });
    }
  });

  if (supplementList.length > 1) {
    return supplementList;
  } else {
    return supplementList;
  }
}

//Food Prefs Parser
export function foodPrefsFunction(data) {
  const foodPrefs = data.inputFields;

  const foodPrefsList = [];

  foodPrefs.forEach((f) => {
    if (f.value) {
      foodPrefsList.push(f.value);
    }
  });

  if (foodPrefsList.length > 0) {
    return (
      "Incorporate food preferences, as able: " + foodPrefsList.join(" / ")
    );
  } else {
    return "";
  }
}

//Appetite Stim Parser
export function appetiteStimulationFunction(data) {
  const appStim = data.appetiteStim;

  const appStimList = [];

  appStim.forEach((s) => {
    if (s.checked) {
      appStimList.push(s.name);
    }
  });

  return appStimList.join(" / ");
}

//Food Allergy/Intolerance Parser
export function foodAllergiesFunction(data) {
  const foodAllergy = data.foodAllergy;
  const foodIntolerance = data.foodIntolerance;

  const foodAllergenList = [];
  const foodIntoleranceList = [];

  foodAllergy.forEach((a) => {
    if (a.allergyname) {
      foodAllergenList.push(a.allergyname);
    }
  });

  foodIntolerance.forEach((i) => {
    if (i.intolerancename) {
      foodIntoleranceList.push(i.intolerancename);
    }
  });

  return { allergy: foodAllergenList, intolerance: foodIntoleranceList };
}

//updating title of malnutrition state
export function titleFinderFunction(name, value) {
  const changedValue = value && Number(value);

  // eslint-disable-next-line no-unused-vars
  const [n, values] = Object.entries(malnutritionRiskScreen).find(
    (pair) => pair[0] === name
  );

  const foundTitle = values.find((item) => item.value === changedValue);
  const returnedTitle =
    foundTitle.value !== "" ? foundTitle.title.slice(2) : "";

  return returnedTitle;
}

//
export function malTitleFunction(data) {
  const firstLevel = Object.values(data.values);

  const count = [];

  firstLevel.forEach((d) => {
    if (d.title === "yes" || d.title === "no") {
      count.push(data.malInput);
    } else {
      count.push(d.title);
    }
  });

  return count.filter(
    (elm) =>
      elm &&
      elm !== "No decrease in food intake" &&
      elm !== "no psychological problems" &&
      elm !== "BMI 23 or greater" &&
      elm !== "CC 31 or greater" &&
      elm !== "goes out" &&
      elm !== "No Weight Loss" &&
      elm !== "No decrease in food intake"
  );
}

export function totalCalcFunction(input) {
  const firstLevel = Object.values(input.values);
  const count = [];

  firstLevel.forEach((d) => {
    count.push(Number(d.num));
  });

  const countReducer = count.reduce((x, y) => x + y);

  let malnutritionName;
  if (countReducer >= 12 && countReducer <= 14) {
    malnutritionName = "Normal nutrition status";
  } else if (countReducer >= 8 && countReducer <= 11) {
    malnutritionName = "At risk of malnutrition";
  } else if (countReducer > 0 && countReducer <= 7) {
    malnutritionName = "Malnourished";
  }

  return { num: countReducer, malName: malnutritionName };
}

export function dietTypeChecker(data) {
  const therapeuticcDiet = data.theraDiet?.toLowerCase();
  const textureType = data.texture?.toLowerCase();
  const liquidType = data.liquids?.toLowerCase();

  if (
    therapeuticcDiet === "regular diet" &&
    textureType === "regular" &&
    liquidType === "regular liquids"
  ) {
    return `Regular diet, Regular texture, Regular liquids`;
  } else if (
    therapeuticcDiet !== "regular diet" &&
    textureType === "regular" &&
    liquidType === "regular liquids"
  ) {
    return `${data.theraDiet}, Regular texture, Regular liquids`;
  } else if (
    therapeuticcDiet === "regular diet" &&
    textureType !== "regular" &&
    liquidType === "regular liquids"
  ) {
    return `Regular diet, ${data.texture} texture, Regular liquids`;
  } else if (
    therapeuticcDiet !== "regular diet" &&
    textureType !== "regular" &&
    liquidType === "regular liquids"
  ) {
    return `${data.theraDiet}, ${data.texture} texture, Regular liquids`;
  } else if (
    therapeuticcDiet === "regular diet" &&
    textureType === "regular" &&
    liquidType !== "regular liquids"
  ) {
    return `Regular diet, Regular texture, ${data.liquids}`;
  } else if (
    therapeuticcDiet === "regular diet" &&
    textureType !== "regular" &&
    liquidType !== "regular liquids"
  ) {
    return `Regular diet, ${data.texture} texture, ${data.liquids}`;
  } else if (
    therapeuticcDiet !== "regular diet" &&
    textureType !== "regular" &&
    liquidType !== "regular liquids"
  ) {
    return `${data.theraDiet}, ${data.texture}, ${data.liquids}`;
  } else if (
    therapeuticcDiet !== "regular diet" &&
    textureType === "regular" &&
    liquidType !== "regular liquids"
  ) {
    return `${data.theraDiet}, Regular texture, ${data.liquids}`;
  } else {
    return "";
  }
}

export function mdsCAAgeneratorBMI(data) {
  const assessType = data.assessType;
  const bmiCheck = getBMI(data.ht, data.wt);
  const bmiType = getBmiType(data.ht, data.wt);
  const bmi = bmiCheck ? bmiCheck.toFixed(1) : 0;

  if (
    assessType === "Admission" ||
    assessType === "Significant Change" ||
    assessType === "Readmission" ||
    assessType === "Annual"
  ) {
    if (bmi <= 25.5 && bmi >= 18.6) return "";
    if (bmi >= 25.6)
      return `${bmiType} secondary to weight that falls within range, possibly associated with excessive energy intake.`;
    if (bmi <= 18.5 && bmi >= 0.99)
      return `${bmiType} secondary to weight that falls within range, possibly associated with inadequate energy intake.`;
  } else {
    return "";
  }
}

export function caaWtChangeProgressNote(data) {
  const wtChangeData = data.wtChange;
  const wtChangeList = [];
  wtChangeData.forEach((wt) => {
    if (wt.toggle) {
      if (
        wt.wtchangetype === "Significant Weight Loss" ||
        wt.wtchangetype === "Significant Weight Gain"
      ) {
        wtChangeList.push({
          notedchange: `${wt.wtchangetype}: (${new Date(
            wt.wtdate
          ).toLocaleDateString("en")}) ${
            wt.wtchange
          } lbs. [ ${lbsChangeCalculation(
            Number(data.wt),
            Number(wt.wtchange)
          ).toFixed(1)} lbs. / ${percentChangeCalc(
            Number(data.wt),
            Number(wt.wtchange)
          ).toFixed(1)}% @ ${dateChange(data.currWtDate, wt.wtdate)} days ]`,
          reason: wt.reason,
        });
      }
    }
  });
  return wtChangeList;
}

export function evaluatePasswordStrength(password) {
  let score = 0;

  if (!password) return "";

  if (password.length > 8) score += 1;
  if (/[a-z]/.test(password)) score += 1;
  if (/[A-Z]/.test(password)) score += 1;
  if (/[#?!@$%^&*-]/.test(password)) score += 1;
  if (/[0-9]/.test(password)) score += 1;

  // eslint-disable-next-line default-case
  switch (score) {
    case 0:
    case 1:
    case 2:
      return { type: "Weak", score };
    case 3:
      return { type: "Medium", score };
    case 4:
    case 5:
      return { type: "Strong", score };
  }
}
