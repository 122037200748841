import React from "react";
import { getBmiType, getBMI } from "../../../../utils/helperFunctions";

const BMI = ({ inputs }) => {
  const bmiType = getBmiType(inputs.ht, inputs.wt);
  const bmi = getBMI(inputs.ht, inputs.wt);

  let content;

  if (getBMI !== 0) {
    content = (
      <div>
        <span>BMI:</span>
        <input
          value={bmi.toFixed(1)}
          disabled={true}
          readOnly={true}
          style={{ width: "2.15rem" }}
        />{" "}
        <span>{bmiType}</span>
      </div>
    );
  }

  return content;
};

export default BMI;
