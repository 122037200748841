//React Components
import { useState } from "react";

//rest api/redux
import { useUpdateItemMutation } from "../../../../app/api/listApiSlice";

//component imports
import ParenteralModalNutrient from "./ParenteralModalNutrient";
import ParenteralModalColumnOne from "./ParenteralModalColumnOne";
import ParenteralModalColumnTwo from "./ParenteralModalColumnTwo";

//styles
import styles from "../../../../css/ParenteralModal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import SaveButton from "../../../../components/buttons/SaveButton";

const ParenteralModal = ({ data, closeModal }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isSaveVisible, setSaveIsVisible] = useState(false);
  const [inputs, setInputs] = useState({
    currPnBoolean: false,
    recPnBoolean: false,
  });

  const [updateItem, { isError, error }] = useUpdateItemMutation();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setInputs((prev) => ({ ...prev, [name]: checked }));
    } else {
      setInputs((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsVisible(true);

    await updateItem(inputs);

    setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    setTimeout(() => {
      setSaveIsVisible(true);
    }, 3000);

    setTimeout(() => {
      setSaveIsVisible(false);
    }, 5000);
  };

  return (
    <>
      <div className={styles.parenteral_modal_top_close}>
        <FontAwesomeIcon
          icon={faXmark}
          size="lg"
          onClick={() => closeModal("Parenteral")}
          style={{
            paddingRight: "10px",
            paddingBottom: "10px",
            cursor: "pointer",
          }}
        />
      </div>
      <div className={styles.parenteral_modal_column_container}>
        <ParenteralModalNutrient
          data={data}
          inputs={inputs}
          handleChange={handleChange}
        />
        {inputs.currPnBoolean && <ParenteralModalColumnOne data={data} />}
        {inputs.recPnBoolean && <ParenteralModalColumnTwo data={data} />}
      </div>
      <SaveButton
        title="Save"
        isVisible={isVisible}
        isSaveVisible={isSaveVisible}
        isError={isError}
        error={error}
        event={handleSubmit}
      />
    </>
  );
};

export default ParenteralModal;
