//styles
import styles from "../../../../css/ParenteralModal.module.css";

function ParenteralModalColumnOne() {
  return (
    <div className={styles.parenteral_modal_column_one_container}>
      <h4
        style={{
          marginBottom: "2px",
          marginTop: "0px",
          alignSelf: "center",
          textDecoration: "underline",
        }}>
        Current Provision of PN
      </h4>
      <label style={{ marginTop: "5px" }}>
        Access Type:
        <select name="currPnAccess">
          <option value="ppn">PPN</option>
          <option value="tpn">TPN</option>
        </select>
      </label>
      <label style={{ marginTop: "5px" }}>
        Duration:
        <select name="currPnDuration">
          <option value={24}>24 hrs</option>
          <option value={18}>18 hrs</option>
          <option value={16}>16 hrs</option>
          <option value={12}>12 hrs</option>
          <option value={8}>8 hrs</option>
        </select>
      </label>
      <div style={{ marginTop: "5px" }}>
        <span>Calc By Volume:</span>
        <input type="radio" name="currPnByVolume" />
        <span style={{ marginLeft: "20px" }}>Calc By Rate:</span>
        <input type="radio" name="currPnByRate" />
      </div>
      <div style={{ marginTop: "5px" }}>
        <label>
          Total Volume:
          <input
            type="number"
            name="currPnTotalVol"
            style={{ width: "2.5rem" }}
          />
        </label>
        <span>"Rate"</span>
      </div>
      <div style={{ marginTop: "5px" }}>
        <label>
          Rate:
          <input
            type="number"
            name="currPnTotalVol"
            style={{ width: "2.5rem", marginLeft: "5px" }}
          />
        </label>
        <span>"Total Volume"</span>
      </div>
      <div style={{ marginTop: "5px" }}>
        <label>
          Dextrose:
          <input
            type="number"
            name=""
            style={{ width: "2.5rem", marginLeft: "5px", marginRight: "5px" }}
          />
          %
        </label>
      </div>
      <div style={{ marginTop: "5px" }}>
        <label>
          Amino Acids:
          <input
            type="number"
            name=""
            style={{ width: "2.5rem", marginLeft: "5px", marginRight: "5px" }}
          />
          %
        </label>
      </div>
      <div style={{ marginTop: "5px" }}>
        <label>
          Lipids:
          <input
            type="number"
            name=""
            style={{ width: "2.5rem", marginLeft: "5px", marginRight: "5px" }}
          />
          mL/day
        </label>
        <select>
          <option value={0.1}>10%</option>
          <option value={0.2}>20%</option>
          <option value={0.3}>30%</option>
        </select>
      </div>
    </div>
  );
}

export default ParenteralModalColumnOne;
