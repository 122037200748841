import { useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { useUpdateItemMutation } from "../../../app/api/listApiSlice";
import { selectItemById } from "../../../app/api/listApiSlice";

//styles
import "../../../css/StartPage.css";
import { Twirl as Hamburger } from "hamburger-react";
import Labs from "../../standard/assessments/middle-assess/Labs";
import InputFieldsMobile from "./InputFieldsMobile";
import StandardButton from "../../../components/buttons/StandardButton";
import SaveButton from "../../../components/buttons/SaveButton";

function Lists({ listId }) {
  const [isOpen, setOpen] = useState(false);
  const [fieldNumber, setFieldNumber] = useState(1);
  const list = useSelector((state) => selectItemById(state, listId));
  const [inputs, setInputs] = useState({
    id: listId,
    labs: list.labs,
    inputFields: list.inputFields,
  });

  const [updateItem] = useUpdateItemMutation();

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  const handleNestedChange = (e, id) => {
    const { name, value } = e.target;
    const [parentKey, childKey] = name.split(".");

    switch (parentKey) {
      case "labs":
        const mappedLabsInputs = inputs.labs.map((item) =>
          item.id === id ? { ...item, [childKey]: value } : item
        );
        setInputs({ ...inputs, [parentKey]: mappedLabsInputs });
        break;
      case "inputFields":
        const mappedInputFieldsInputs = inputs.inputFields.map((item) =>
          item.id === id ? { ...item, [childKey]: value } : item
        );
        setInputs({ ...inputs, [parentKey]: mappedInputFieldsInputs });
        break;
      default:
        console.log(`${parentKey} cannot be found`);
    }
  };

  /// update nested lab component
  const handleNestedLabChange = (id, lab) => {
    const mappedLabsInputs = inputs.labs.map((item) =>
      item.id === id ? { ...item, range: lab } : item
    );
    setInputs({ ...inputs, labs: mappedLabsInputs });
  };

  /// add additional input fields
  const handleAddition = (name) => {
    switch (name) {
      case "inputFields":
        if (fieldNumber <= 9) {
          setFieldNumber((prev) => prev + 1);
          setInputs({
            ...inputs,
            inputFields: [
              ...inputs.inputFields,
              { id: uuid(), fieldnum: fieldNumber, value: "" },
            ],
          });
        } else {
          return;
        }
        break;
      default:
        console.log(`no ${name} found`);
    }
  };

  /// Remove input fields
  const handleRemove = (name, id) => {
    switch (name) {
      case "inputFields":
        setFieldNumber((prev) => prev - 1);
        const mappedInputFieldsInputs = inputs.inputFields.filter(
          (item) => item.id !== id
        );
        setInputs({ ...inputs, inputFields: mappedInputFieldsInputs });
        break;
      default:
        console.log(`no ${name} found`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await updateItem(inputs);
  };

  return (
    <div className="list-container-outer_mobile">
      <div className="start_page_line_mobile">
        <div className="assess_too_see_mobile">
          <Hamburger toggled={isOpen} toggle={handleToggle} size={14} rounded />
          <div className="inner-container-list-to-see_mobile">
            <span>
              {list.lastName}, {list.firstName}
            </span>
            <span>{list.room}</span>
            <span>{list.assessType}</span>
          </div>
          {isOpen && (
            <div className="inner_container_list_details">
              <div>
                <span>Diet: {list.theraDiet ? list.theraDiet : ""}</span>
              </div>
              <div>
                <span>Texture: {list.texture ? list.texture : ""}</span>
              </div>
              <div>
                <span>Liquids: {list.liquids ? list.liquids : ""}</span>
              </div>
              <span>Labs: </span>
              <div className="labs_container_list_mobile">
                {inputs.labs.map((item) => (
                  <Labs
                    item={item}
                    key={item.id}
                    inputs={inputs}
                    handleNestedChange={handleNestedChange}
                    handleNestedLabChange={handleNestedLabChange}
                  />
                ))}
              </div>
              <span>Food Prefs: </span>
              <div className="food_prefs_container_mobile">
                {inputs.inputFields.length === 0 && (
                  <span> Click the button below to add a field</span>
                )}
                {inputs.inputFields.map((item) => (
                  <InputFieldsMobile
                    item={item}
                    key={item.id}
                    handleNestedChange={handleNestedChange}
                    handleRemove={handleRemove}
                  />
                ))}
                <StandardButton
                  event={handleAddition}
                  name="inputFields"
                  title="Add New Preferences"
                  style={{ paddingTop: "6px" }}
                />
              </div>
              <div className="save_button_mobile">
                <SaveButton title="Save" event={handleSubmit} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Lists;
