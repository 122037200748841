import { useEffect } from "react";
import { EVENTS } from "../../config/events";
import { useNavigate } from "react-router-dom";
import { useSendLogoutMutation } from "../api/authAPISlice";
import { useDispatch } from "react-redux";
import { setAuthentication } from "../auth/twoFaSlice";

const AppLogout = ({ children }) => {
  const dispatch = useDispatch();
  const [sendLogout] = useSendLogoutMutation();

  const navigate = useNavigate();
  let timer;

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();

      Object.values(EVENTS).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, 900000);
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(EVENTS).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutAction = () => {
    dispatch(setAuthentication(false));
    sendLogout();
    navigate("/");
  };
  return children;
};

export default AppLogout;
