import styles from "../../../../css/Assessment.module.css";
import { MULTI_OBJ } from "../../../../utils/staticData";
import {
  ageCalc,
  equationCalculation,
} from "../../../../utils/helperFunctions";

const Equations = ({ inputs, handleChange }) => {
  const age = ageCalc(inputs.age);
  const equations = equationCalculation(inputs, age, inputs.sex);

  return (
    <div className={styles.form_calorie_calculation}>
      <span>Mifflin-St. Jeor:</span>
      <span style={{ marginLeft: "0.5rem", marginRight: "1.5rem" }}>
        {equations.mfsEquation > 0 && inputs.ht && inputs.wt
          ? `${equations.mfsEquation.toFixed(0)} Calories`
          : 0}
      </span>
      <span>Livingston:</span>
      <span style={{ marginLeft: "0.5rem", marginRight: "1.5rem" }}>
        {equations.livEquation > 0 && inputs.ht && inputs.wt
          ? `${equations.livEquation.toFixed(0)} Calories`
          : 0}
      </span>
      <span>Activity Factor:</span>
      <select name="actFactor" value={inputs.actFactor} onChange={handleChange}>
        {MULTI_OBJ.activity.map((i, k) => (
          <option value={i.number} key={k}>
            {i.name}
          </option>
        ))}
      </select>
      <span>Injury Factor:</span>
      <select name="injFactor" value={inputs.injFactor} onChange={handleChange}>
        {MULTI_OBJ.injury.map((i, k) => (
          <option value={i.number} key={k}>
            {i.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Equations;
