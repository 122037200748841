import { useSelector } from "react-redux";
import { selectItemById } from "../../../app/api/listApiSlice";
import { useNavigate } from "react-router-dom";
import styles from "../../../css/Assessment.module.css";
import StandardButton from "../../../components/buttons/StandardButton";

const TooSeeList = ({ listId }) => {
  const list = useSelector((state) => selectItemById(state, listId));
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/Assessment/${listId}`);
  };
  return (
    <li className={styles.inner_container_list_to_see}>
      <span>{list.assessnum}.</span>
      <span>
        {list.lastName}, {list.firstName}
      </span>
      <span>{list.room}</span>
      <span>
        <strong>( {list.assessType} )</strong>
      </span>
      <StandardButton event={handleNavigate} title="Go To Page" />
    </li>
  );
};

export default TooSeeList;
