import { v4 as uuid } from "uuid";

export const NESTED_IDS = {
  sup: "supplementList",
  allergy: "foodAllergy",
  intolerance: "foodIntolerance",
  inputfields: "inputFields",
  microinput: "micronutrientInputs",
  nutritionDiagnosis: "nutritionDiagnosis",
  nutritionRecs: "nutritionRecs",
};

export const WT_STATUS = [
  "Relatively Stable",
  "Slight Weight Loss",
  "Slight Weight Gain",
  "Weight Loss",
  "Weight Gain",
  "Significant Weight Loss",
  "Significant Weight Gain",
];

export const BWT_ROOMS = [
  "101-A",
  "101-B",
  "102-A",
  "102-B",
  "103-A",
  "103-B",
  "104-A",
  "104-B",
  "105-A",
  "105-B",
  "106-A",
  "106-B",
  "107-A",
  "107-B",
  "108-A",
  "108-B",
  "109-A",
  "109-B",
  "110-A",
  "110-B",
  "111-A",
  "111-B",
  "112-A",
  "112-B",
  "113-A",
  "113-B",
  "114-A",
  "114-B",
  "115-A",
  "115-B",
  "116-A",
  "116-B",
  "117-A",
  "117-B",
  "118-A",
  "118-B",
  "119-A",
  "119-B",
  "120-A",
  "120-B",
  "121-A",
  "121-B",
  "122-A",
  "122-B",
  "123-A",
  "124-B",
  "125-A",
  "125-B",
  "201-A",
  "201-B",
  "202-A",
  "202-B",
  "203-A",
  "203-B",
  "204-A",
  "204-B",
  "205-A",
  "205-B",
  "206-A",
  "206-B",
  "207-A",
  "207-B",
  "208-A",
  "208-B",
  "209-A",
  "209-B",
  "210-A",
  "210-B",
  "211-A",
  "211-B",
  "212-A",
  "212-B",
  "213-B",
  "214-A",
  "214-B",
  "215-A",
  "215-B",
  "216-A",
  "216-B",
  "217-A",
  "217-B",
  "218-A",
  "218-B",
  "219-A",
  "219-B",
  "220-A",
  "220-B",
  "221-A",
  "221-B",
  "222-A",
  "222-B",
  "223-A",
  "223-B",
  "224-A",
  "224-B",
  "225-A",
  "226-A",
  "227-A",
  "228-A",
  "229-A",
  "230-A",
  "301-A",
  "301-B",
  "302-A",
  "302-B",
  "303-A",
  "303-B",
  "304-A",
  "304-B",
  "305-A",
  "305-B",
  "306-A",
  "306-B",
  "307-A",
  "307-B",
  "308-A",
  "308-B",
  "309-A",
  "309-B",
  "310-A",
  "310-B",
  "311-A",
  "312-A",
  "313-A",
  "314-A",
  "315-A",
  "316-A",
  "317-A",
  "318-A",
  "319-A",
  "320-A",
  "321-A",
  "322-A",
  "322-B",
  "323-A",
  "324-A",
  "324-B",
  "326-A",
  "326-B",
  "328-A",
  "328-B",
];

export const WR_ROOMS = [
  "201-Cedar",
  "202-Cedar",
  "203-Cedar",
  "204-Cedar",
  "205-Cedar",
  "206-Cedar",
  "207-Cedar",
  "208-Cedar",
  "209-Cedar",
  "210-Cedar",
  "211-Cedar",
  "212-Cedar",
  "213-Cedar",
  "214-Cedar",
  "215-Cedar",
  "216-Cedar",
  "217-Cedar",
  "218-Cedar",
  "219-Cedar",
  "220-Cedar",
  "221-Cedar",
  "222-Cedar",
  "223-Cedar",
  "224-Cedar",
  "225-Cedar",
  "226-Cedar",
  "227-Cedar",
  "228-Cedar",
  "229-Cedar",
  "230-Cedar",
  "231-Cedar",
  "232-Cedar",
  "233-Cedar",
  "234-Cedar",
  "235-Cedar",
  "236-Cedar",
  "237-Cedar",
  "238-Cedar",
  "239-Cedar",
  "240-Juniper",
  "241-Juniper",
  "242-Juniper",
  "243-Juniper",
  "244-Juniper",
  "245-Juniper",
  "246-Juniper",
  "247-Juniper",
  "248-Juniper",
  "249-Juniper",
  "250-Juniper",
  "251-Juniper",
  "252-Juniper",
  "253-Juniper",
  "254-Juniper",
  "255-Juniper",
  "256-Juniper",
  "257-Juniper",
];

export const CAL_VALUES = {
  calFluidValslow: ["", 20, 25, 30, 35],
  calFluidValshigh: ["", 25, 30, 35, 40],
  proteinValslow: ["", 0.8, 1.0, 1.2],
  proteinvalshigh: ["", 1.0, 1.2, 1.5],
};

export const WT_CHANGE_TYPE = [
  "Relatively Stable Weight",
  "Slight Weight Loss",
  "Slight Weight Gain",
  "Weight Gain",
  "Weight Loss",
  "Significant Weight Gain",
  "Significant Weight Loss",
];

export const MULTI_OBJ = {
  activity: [
    { name: "none", number: "0" },
    { name: "ambulatory", number: "1.3" },
    { name: "bedrest", number: "1.2" },
  ],
  injury: [
    { name: "none", number: 0 },
    { name: "minor surgery", number: "1.1" },
    { name: "major surgery", number: "1.15" },
    { name: "mild infect", number: "1.1" },
    { name: "minor surgery", number: "1.1" },
    { name: "moderate infection", number: "1.3" },
    { name: "severe infection", number: "1.5" },
    { name: "skeletal trauma", number: "1.25" },
    { name: "blunt trauma", number: "1.15" },
    { name: "head trauma", number: "1.5" },
    { name: "<20% BSA burns", number: "1.25" },
    { name: "20-40% BSA burns", number: "1.6" },
    { name: ">50% BSA burns", number: "1.95" },
  ],
};

export const problemList = [
  "",
  "Inadequate/supboptimal energy intake",
  "Excessive energy intake",
  "Predicted suboptimal energy intake",
  "Predicted excessive energy intake",
  "Excessive oral intake",
  "Limited food acceptance",
  "Less than optimal enteral nutritional composition or modality",
  "Less than optimal parenteral nutritiona composition or modality",
  "Inadequate fluid intake",
  "Inconsistent carbohydrate intake",
  "Inadequate/suboptimal intake of calcium",
  "Predicted suboptimal nutrient intake",
  "Predicted excessive nutrient intake",
  "Swallowing difficulty",
  "Biting/Chewing (masticatory) difficulty",
  "Underweight",
  "Unintentional Weight Loss",
  "Overweight/Obesity",
  "Unintended weight gain",
  "Food- and nutrition-related knowledge deficit",
  "Not ready for diet/lifestyle change",
  "Limited adherence to nutrition-related recommendations",
  "Self-feeding difficulty",
  "Increased Nutrient Needs",
  "Alteration in GI function",
  "Malnutrition",
];

export const malnutritionRiskScreen = {
  A: [
    { id: uuid(), value: "", title: "" },
    { id: uuid(), value: 0, title: "0=Severe Decrease" },
    { id: uuid(), value: 1, title: "1=Moderate decrease in food intake" },
    { id: uuid(), value: 2, title: "2=No decrease in food intake" },
  ],
  B: [
    { id: uuid(), value: "", title: "" },
    {
      id: uuid(),
      value: 0,
      title: "0=Weight loss greater than 3 kg (6.6 lbs)",
    },
    { id: uuid(), value: 1, title: "1=Unknown Wt Changes" },
    {
      id: uuid(),
      value: 2,
      title: "2=Weight loss between 1 and 3 kg (2.2 and 6.6 lbs)",
    },
    { id: uuid(), value: 3, title: "3=No Weight Loss" },
  ],
  C: [
    { id: uuid(), value: "", title: "" },
    { id: uuid(), value: 0, title: "0=bed or chair bound" },
    {
      id: uuid(),
      value: 1,
      title: "1=able to get out of bed/chair but does not go out",
    },
    { id: uuid(), value: 2, title: "2=goes out" },
  ],
  D: [
    { id: uuid(), value: "", title: "" },
    { id: uuid(), value: 0, title: "0=yes" },
    { id: uuid(), value: 2, title: "2=no" },
  ],
  E: [
    { id: uuid(), value: "", title: "" },
    { id: uuid(), value: 0, title: "0=severe dementia or depression" },
    { id: uuid(), value: 1, title: "1=mild dementia" },
    { id: uuid(), value: 2, title: "2=no psychological problems" },
  ],
  F1: [
    { id: uuid(), value: "", title: "" },
    { id: uuid(), value: 0, title: "0=BMI less than 19" },
    { id: uuid(), value: 1, title: "1=BMI 19 to less than 21" },
    { id: uuid(), value: 2, title: "2=BMI 21 to less than 23" },
    { id: uuid(), value: 3, title: "3=BMI 23 or greater" },
  ],
  F2: [
    { id: uuid(), value: "", title: "" },
    { id: uuid(), value: 0, title: "0=CC less than 31" },
    { id: uuid(), value: 3, title: "2=CC 31 or greater" },
  ],
};

export const hardCodeArrays = {
  theraDiets: [
    "Regular diet",
    "Limited Concentrated Sweets diet",
    "No Added Salt diet",
    "2 gm Sodium/Limited Concentrated Sweets diet",
    "2 gm Sodium diet",
    "Gluten Restricted",
    "Full Liquid diet",
    "Clear Liquid diet",
    "Vegetarian diet",
    "Renal diet",
    "NPO",
  ],
  textMod: [
    "Regular texture",
    "Dysphagia Mechanical texture",
    "Finger Foods",
    "Mechanical Soft (Ground Meat) texture",
    "Mechanical Soft (Chopped Meat) texture",
    "Mechanical Soft (Puree Meat) texture",
    "Ground Meats",
    "Chopped Meats",
    "Puree texture",
  ],
  upperdent: [
    "Natural Teeth",
    "Full Upper",
    "Partial Upper",
    "Edentulous",
    "Missing Teeth",
  ],
  lowerdent: [
    "Natural Teeth",
    "Full Lower",
    "Partial Lower",
    "Edentulous",
    "Missing Teeth",
  ],
  intakeamt: ["", ">50%", "~50%", "<50%", "N/A"],
  liquidMod: ["Regular Liquids", "Nectar Liquids", "Honey Liquids"],
  eatSelfPerformance: [
    "",
    "Independent",
    "Supervision",
    "Limited Assistance",
    "Extensive Assistance",
    "Total Dependence",
    "Resident Refused",
    "N/A",
  ],
  eatSupportPerform: [
    "",
    "no setup",
    "setup help only",
    "one person physicial assistance",
    "resident refused",
  ],
  portionSize: ["", "Small", "Regular", "Large"],

  fluidRestriction: [
    "",
    "No Restriction",
    "500 mL",
    "750 mL",
    "1000 mL",
    "1200 mL",
    "1500 mL",
    "1800 mL",
    "2000 mL",
    "2500 mL",
  ],
};

export const DIETS = {
  cardiodiets: [
    "2 gm Sodium diet",
    "No Added Salt diet",
    "2 gm Sodium/Limited Concentrated Sweets diet",
    "Limited Concentrated Sweets/NAS diet",
  ],
  dmdiets: [
    "Limited Concentrated Sweets diet",
    "2 gm Sodium/Limited Concentrated Sweets diet",
    "Limited Concentrated Sweets/NAS diet",
  ],
  renaldiets: ["Renal diet"],
  glutendiets: ["Gluten Restricted"],
};

export const KEYWORDS = {
  cadKeyWords: [
    "CHF",
    "Congestive Heart Failure",
    "CAD",
    "HTN",
    "Stress-induced cardiomyopathy",
    "Atrial fibrillation",
    "Heart failure",
    "Edema",
    "Chronic lower extremity edema",
    "Anterior choroidal artery infarction",
    "Chonric systolic heart failure",
    "CVA",
    "HFpEF",
  ],
  liverKeyWords: ["Decompensated ETOH cirrhosis", "Portal HTN"],
  renalKeyWords: [
    "CKD Stage III",
    "Acute Kidney Injury",
    "CKD Stage IV",
    "CKD Stage V",
  ],
  dmKeyWords: ["Type II DM", "Type I DM", "prediabetes"],
  glKeyWords: ["Celiac's disease"],
};

export const THERADIETCHECKER = [
  "Regular diet",
  "Regular",
  "Liberal Geriatric (Regular) diet",
];

export const TEXTURECHECKER = ["Regular", "Regular Texture"];

export const letter = "abcdefghijklmnopqrstuvwxyz".split("");

export const tfs = [
  {
    id: "1",
    name: "Jevity 1.2",
    provider: "Abott",
    calories: 1.2,
    protein: 0.0555,
    fluid: 0.807,
    description:
      "Fiber-fortified therapeutic nutrition that provides complete, balanced nutrition for long- or short-term tube feeding.",
    features: [
      "4 g dietary fiber/8 fl oz (2.1 g of a unique fiber blend and 1.9 g of scFOS) which helps meet daily fiber intake. Fiber helps moderate bowel function.",
      "Includes 1.9 g of scFOS/8 fl oz (8 g/L and 12 g/1.5L). scFOS are prebiotics that stimulate the growth of beneficial bacteria in the colon.",
      "Meets or exceeds 100% of the RDIs for 25 essential vitamins and minerals in 1500 Cal (1250 mL).",
      "Kosher.",
      "Halal.",
      "Gluten-free.",
      "Suitable for lactose intolerance.",
    ],
  },
  {
    id: "2",
    name: "Jevity 1.5",
    provider: "Abott",
    calories: 1.5,
    protein: 0.0638,
    fluid: 0.76,
    description:
      "Calorically dense, fiber-fortified therapeutic nutrition that provides complete, balanced nutrition for long- or short-term tube feeding.",
    features: [
      "Concentrated calories (1.5 Cal/mL) to help tube-fed patients gain and maintain healthy weight.",
      "5 g dietary fiber/8 fl oz (2.6 g of a unique fiber blend and 2.4 g of scFOS) which helps meet daily fiber needs. Fiber helps moderate bowel function.",
      "Includes 2.4 g scFOS/8 fl oz. (10 g/L and 15 g/1.5 L) scFOS are prebiotics that stimulate the growth of beneficial bacteria in the colon.",
      "Meets or exceeds 100% of the RDIs for 25 essential vitamins and minerals in 1500 Cal (1000 mL).",
      "Kosher.",
      "Halal.",
      "Gluten-free.",
      "Suitable for lactose intolerance.",
    ],
  },
  {
    id: "3",
    name: "Osmolite 1.2",
    provider: "Abott",
    calories: 1.2,
    protein: 0.0555,
    fluid: 0.82,
    description:
      "Therapeutic nutrition that provides complete, balanced nutrition for long- or short-term tube feeding for patients who may benefit from increased protein and calories.",
    features: [
      "Concentrated calories (1.2 Cal/mL).",
      "Fat blend contains high-oleic safflower, canola, and MCT oils. Patients who absorb lipids improperly may benefit from consuming a formula that contains MCTs as part of the fat source.",
      "1500 Calories (1250 mL) provides at least 100% of the RDIs for 25 essential vitamins and minerals.",
      "Kosher.",
      "Halal.",
      "Gluten-free.",
      "Suitable for lactose intolerance.",
    ],
  },
  {
    id: "4",
    name: "Osmolite 1.5",
    provider: "Abott",
    calories: 1.5,
    protein: 0.0627,
    fluid: 0.762,
    description:
      "Therapeutic nutrition that provides complete, balanced nutrition for long- or short-term tube feeding for patients with increased calorie and protein needs, or for those with limited volume tolerance.",
    features: [
      "Concentrated calories (1.5 Cal/mL) to help patients gain and maintain healthy weight.",
      "Fat blend contains high-oleic safflower, canola, and MCT oils. Patients who absorb lipids improperly may benefit from consuming a formula that contains MCTs as part of the fat source.",
      "1500 Calories (1000 mL) provides at least 100% of the RDIs for 25 essential vitamins and minerals.",
      "Kosher.",
      "Halal.",
      "Gluten-free.",
      "Suitable for lactose intolerance.",
    ],
  },
  {
    id: "5",
    name: "TwoCal HN",
    provider: "Abott",
    calories: 2.0,
    protein: 0.0835,
    fluid: 0.7,
    description:
      "Calorie and protein dense nutrition to support patients with volume intolerance and/or fluid restriction. Often provided in 2 fl oz amounts QID with Med Pass programs to assist with weight gain or maintenance.",
    features: [
      "2 Cal/mL to help patients gain and maintain healthy weight.",
      "Protein to help support lean body mass and wound healing.",
      "1.2 g of scFOS/8 fl oz (5 g/L). scFOS are prebiotics that stimulate the growth of beneficial bacteria in the colon.",
      "Tetra: Provides at least 25% of the RDIs for 24 essential vitamins and minerals in 475 calories (8 fl oz). RTH: Provides at least 100% of the RDIs for 25 essential vitamins and minerals in 1900 calories (948 mL).",
      "Kosher.",
      "Halal.",
    ],
  },
  {
    id: "6",
    name: "Promote",
    provider: "Abott",
    calories: 1,
    protein: 0.0625,
    fluid: 0.839,
    description:
      "A complete, balanced, high-protein formula for patients who need a higher proportion of calories from protein. ",
    features: [
      "High in protein (25% of Cal, 15 g/8 fl oz). Protein is needed for lean body tissue maintenance and repair.",
      "1500 calories (1500 mL) provides at least 100% of the RDIs for 26 essential vitamins and minerals.",
      "Fat blend has safflower, MCTs, and soy oils.",
      "1 Cal/mL.",
      "Kosher.",
      "Halal.",
      "Gluten-free.",
      "Suitable for lactose intolerance.",
    ],
  },
  {
    id: "7",
    name: "Vital 1.5",
    provider: "Abott",
    calories: 1.5,
    protein: 0.0675,
    fluid: 0.764,
    description:
      "Peptide-based therapeutic nutrition that provides complete, balanced nutrition for long- or short-term for patients who require tube feeding and are experiencing malabsorption, maldigestion, or impaired GI function and/or GI intolerance.",
    features: [
      "Advanced blend of hydrolyzed protein, structured lipid, and prebiotics (scFOS) to support absorption and tolerance.",
      "Hydrolyzed, peptide-based protein system.",
      "MCT/canola oil structured lipid, a next-generation fat to promote absorption of fatty acids.",
      "1.4 g of scFOS/8 fl oz (6 g/L). scFOS are prebiotic fibers that stimulate the growth of beneficial bacteria in the colon.",
      "1.5 Cal/mL—concentrated calories for fluid-restricted patients.",
      "Contains elevated levels of antioxidants vitamins C and E and selenium to help reduce free radical damage.",
      "1500 Calories (1000 mL) provides at least 100% of the RDIs for 25 essential vitamins and minerals.",
      "Gluten-free.",
      "Suitable for lactose intolerance.",
    ],
  },
  {
    id: "8",
    name: "Suplena Carb Steady",
    provider: "Abott",
    calories: 1.8,
    protein: 0.045,
    fluid: 0.738,
    description:
      "Therapeutic nutrition specifically designed for nondialyzed people with chronic kidney disease and on a protein-restricted diet without protein-energy wasting (malnutrition).",
    features: [
      "Has CARBSTEADY®, a carbohydrate blend designed to help minimize blood sugar response.",
      "High in calories - 1.8 Cal/mL.",
      "Low in protein — 10% of total calories.",
      "Low in phosphorus and potassium.",
      "A good source of fiber, including prebiotics.",
      "Therapeutic nutrition that can help maintain nutritional status while adhering to renal diets for nondialyzed people with chronic kidney disease, or nondialyzed without protein-energy wasting (malnutrition).",
      "Kosher.",
      "Halal.",
      "Gluten-free.",
      "Suitable for lactose intolerance.",
    ],
  },
  {
    id: "9",
    name: "Pivot 1.5",
    provider: "Abott",
    calories: 1.5,
    protein: 0.0938,
    fluid: 0.759,
    description:
      "Is peptide-based complete, balanced therapeutic nutrition for short- or long-term tube feeding for metabolically stressed surgical, trauma, burn, or head and neck cancer patients who could benefit from an immune-modulating enteral formula.",
    features: [
      "Provides very high protein (93.8 g/L, 25% of calories) to support protein synthesis, tissue repair and wound healing.",
      "Provides 1.5 Cal/mL—concentrated calories for fluid-restricted patients.",
      "Immune Support: Arginine (13 g/L, 3.5% of calories) to support proliferation and function of immune cells.",
      "Immune Support: Glutamine (inherent) (7.6 g/L) for GI-tract integrity and energy for immune cells.",
      "Immune Support: Omega-3 fatty acids (EPA, 2.6 g/L; DHA, 1.1 g/L) to help modulate inflammation and support immune function.",
      "Advanced blend of hydrolyzed protein, structured lipid, and prebiotics (scFOS) to promote absorption and tolerance.",
      "Hydrolyzed, peptide-based protein system.",
      "MCT/fish oil structured lipid, a well-tolerated7,8 and absorbed8 next generation fat to promote absorption of fatty acids.",
      "1.8 g of scFOS/8 fl oz (7.5 g/L). scFOS are prebiotic soluble fibers that stimulate the growth of beneficial bacteria in the colon.",
      "Elevated antioxidants vitamins C and E, selenium and beta-carotene to help reduce tissue and cell damage due to oxidative stress.",
      "1950 calories (1300 mL) provides at least 100% of the RDIs for 25 essential vitamins and minerals.",
      "Halal.",
      "Gluten-free.",
      "Suitable for lactose intolerance.",
    ],
  },
  {
    id: "10",
    name: "Nepro Carb Steady",
    provider: "Abott",
    calories: 1.8,
    protein: 0.081,
    fluid: 0.727,
    description:
      "Therapeutic nutrition specifically designed to help meet the nutritional needs of people on dialysis (stage 5 CKD).",
    features: [
      "Has CARBSTEADY®, a carbohydrate blend designed to help minimize blood sugar response.",
      "Designed for people on dialysis with low phosphorus and potassium.",
      "Excellent source of high-quality protein (19 g /8 fl oz) to help meet nutritional needs and replace protein lost during dialysis.",
      "In a study of Nepro as supplemental nutrition, patients on dialysis had increased serum albumin, increased serum prealbumin, and improved nutritional status (as signified by subjective global assessment).",
      "High in calories - 1.8 Cal/mL.",
      "A good source of fiber, including prebiotics.",
      "Kosher.",
      "Halal.",
      "Gluten-free.",
      "Suitable for lactose intolerance.",
    ],
  },
  {
    id: "11",
    name: "Glucerna 1.2",
    provider: "Abott",
    calories: 1.2,
    protein: 0.06,
    fluid: 0.805,
    description:
      "Is a calorically dense formula with CarbSteady®, a specialized blend of low glycemic and slowly digestible carbohydrates, clinically shown to help minimize blood glucose response compared to a standard 1.2 Cal formula in patients with type 2 diabetes.1 GLUCERNA 1.2 CAL has CarbSteady® which includes low glycemic carbohydrates for enhanced glycemic management compared to high glycemic carbohydrates. Specialized nutrition for enhanced glycemic management compared to a standard formula. For patients with type 1 or type 2 diabetes or elevated glucose response.",
    features: [
      "20% of calories from protein to help promote anabolism.",
      "Glucerna 1.2 Cal contains a fat blend which is within the American Heart Association and American Diabetes Association recommendations favoring monounsaturated and polyunsaturated fats and limiting saturated fat to help manage lipid profiles. ",
      "Provides an excellent source (570 mg ALA/8 fl oz) of plant-based omega-3 fatty acid alpha linolenic acid (35% of the 1.6 g DV for ALA) to support cardiovascular health.",
      "At 1.2 Cal/mL, patient needs can be met with less volume.",
      "Kosher.",
      "Halal.",
      "Gluten-free.",
      "Suitable for lactose intolerance.",
    ],
  },
  {
    id: "12",
    name: "Glucerna 1.5",
    provider: "Abott",
    calories: 1.5,
    protein: 0.0825,
    fluid: 0.759,
    description:
      "Is a calorically dense formula with CARBSTEADY®, a specialized blend of low glycemic and slowly digestible carbohydrates, designed to help minimize blood glucose response compared to high glycemic carbohydrates. Specialized nutrition for enhanced glycemic management compared to a standard formula. For patients with type 1 or type 2 diabetes or elevated glucose response. ",
    features: [
      "Contains a specialized carbohydrate blend which is clinically shown to significantly reduce postprandial blood glucose response and glycemic variability compared to a standard 1.2 Cal formulation in patients with type 2 diabetes.",
      "22% of calories from protein to help promote anabolism.",
      "Contains a fat blend which is within the American Diabetes Association and American Heart Association recommendations favoring monounsaturated fats and polyunsaturated fat and limiting saturated fat to help manage lipid profiles.",
      "Provides an excellent source (712 mg of ALA/8 fl oz) of plant-based omega-3 fatty acid alpha linolenic acid (44% of the 1.6 g DV for ALA) to support cardiovascular health.",
      "At 1.5 Cal/mL, patient needs can be met with less volume.",
      "Kosher.",
      "Halal.",
      "Gluten-free.",
    ],
  },
  {
    id: "13",
    name: "Replete",
    provider: "Nestle",
    calories: 1,
    protein: 0.064,
    fluid: 0.84,
    descrption:
      "A Very High Protein Complete Nutrition formula, designed for patients with increased protein requirement. Elevated protein for patients recovering from surgery, burns or pressure injuries",
    features: [
      "Protein source is casein (milk); does not contain soy protein or soy lecithin",
      "Nutritional applications: Wounds: pressure injuries (stages 1–4, unstageable), burns, surgical wounds, high protein needs",
      "Suitable for the following diets: Lactose intolerance, gluten-free, kosher",
    ],
  },
  {
    id: "14",
    name: "Liquid Hope",
    provider: "Liquid Hope",
    calories: 1.3,
    protein: 0.06479,
    fluid: 1.2761,
    description:
      "Is the first organic, plant-based, whole foods feeding tube formula and oral meal replacement optimized for adults. It is formulated to help maintain healthy weight and provide complete nutrition.",
    features: [
      "Whole Food Ingredients (just pureed plant foods)",
      "Vegetables and Herbs",
      "Phytonutrients",
      "ALA-Alpha Linoleic Acid (a precursor to DHA and EPA)",
      "A low renal acid load (PRAL)",
      "Fermentable/functional fiber (a precursor to short chain fatty acids)",
      "A low omega 3 to omega 6 ratio (2.6:1)",
      "Anti-inflammatory effects",
    ],
  },
  {
    id: "15",
    name: "Nutren 1.5",
    provider: "Nestle",
    calories: 1.5,
    protein: 0.068,
    fluid: 0.764,
    description:
      "A calorically dense, complete nutrition tube feeding formula for the nutritional management of those with limited fluid tolerance and/or increased energy needs.",
    features: [
      "Contains a high-quality casein and soy protein blend to help support lean body mass.",
      "Primary fat source is canola oil, which is naturally rich in monounsaturated fat and contains ALA, an omega-3 fatty acid.",
      "Gluten-free and lactose intolerance.",
    ],
  },
  {
    id: "16",
    name: "Isosource 1.5",
    provider: "Nestle",
    calories: 1.5,
    protein: 0.068,
    fluid: 0.764,
    description:
      "Is a calorically dense complete nutrition formula with fiber, designed for individuals with increased calorie needs and/or limited fluid tolerance.",
    features: [
      "High-quality casein and soy protein blend to help support lean body mass.",
      "Fiber blend is 50% insoluble and 50% soluble fibers to help support normal bowel function and digestive health.",
      "Primary fat source is canola oil which is naturally rich in monounsaturated fat and contains ALA, an omega-3 fatty acid.",
      "Gluten-free and lactose intolerance.",
    ],
  },
];
