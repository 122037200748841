import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { NESTED_IDS, problemList } from "../../../utils/staticData.js";

import styles from "../../../css/LongTermLists.module.css";
const LongTermNutritionDiagnosis = ({
  item,
  handleNestedChange,
  handleRemove,
}) => {
  return (
    <div>
      <div className={styles.nutrition_diagnosis_outer_container}>
        <h5>Diagnosis #{item.num}</h5>
        <span>
          <FontAwesomeIcon
            icon={faTrash}
            onClick={(e) =>
              handleRemove(NESTED_IDS.nutritionDiagnosis, item.id)
            }
            style={{
              color: "#6e6d70",
              cursor: "pointer",
              paddingLeft: "6px",
            }}
          />
        </span>
      </div>
      <div className={styles.nutrition_diagnosis_inner_container}>
        <span>Problem</span>
        <select
          name="nutritionDiagnosis.problem"
          value={item.problem}
          onChange={(e) => handleNestedChange(e, item.id)}>
          {problemList.map((item) => (
            <option value={item} key={item.toLowerCase()}>
              {item}
            </option>
          ))}
        </select>
        <span>Etiology</span>
        <textarea
          className={styles.data_entry_nutrition_diagnosis_textarea}
          name="nutritionDiagnosis.etiology"
          value={item.etology}
          defaultValue={item.etiology}
          onChange={(e) => handleNestedChange(e, item.id)}></textarea>
        <span>Symptoms</span>
        <textarea
          className={styles.data_entry_nutrition_diagnosis_textarea}
          name="nutritionDiagnosis.symptom"
          value={item.symtpom}
          defaultValue={item.symptom}
          onChange={(e) => handleNestedChange(e, item.id)}></textarea>
      </div>
    </div>
  );
};

export default LongTermNutritionDiagnosis;
