//hooks
import useMobile from "../hooks/useMobile";

//components
import TwoFaStandard from "../features/standard/twofa/TwoFaStandard";
import TwoFaMobile from "../features/mobile/TwoFA/TwoFaMobile";

function TwoFA() {
  const isMobile = useMobile();

  return !isMobile ? <TwoFaStandard /> : <TwoFaMobile />;
}

export default TwoFA;
