//components
import Spinner from "../../../components/loaders/Spinner";
import Lists from "./Lists";

//hooks
import useAuth from "../../../hooks/useAuth";
import { useGetListQuery } from "../../../app/api/listApiSlice";

function TooSeeListMobile() {
  const { data, isSuccess, isLoading, isError, error } = useGetListQuery();

  const { location, username } = useAuth();

  let content;
  if (isLoading) content = <Spinner />;

  if (isSuccess) {
    const { ids, entities } = data;

    const filteredList = ids.filter(
      (listId) =>
        entities[listId].assessnum > 0 &&
        entities[listId].location === location &&
        entities[listId].user === username
    );

    content = filteredList.map((listId) => (
      <Lists key={listId} listId={listId} />
    ));
  }

  return content;
}

export default TooSeeListMobile;
