import React from "react";

function ParenteralModalColumnTwo() {
  return (
    <div>
      <h4 style={{ marginBottom: "2px", marginTop: "0px" }}>
        Recommended Provision of PN
      </h4>
    </div>
  );
}

export default ParenteralModalColumnTwo;
