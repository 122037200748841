//styles
import styles from "../../../../css/EnteralModal.module.css";

const EnteralModalColumnThree = ({
  tfDescription,
  foundTF,
  handleClearTFDescription,
}) => {
  return (
    <div>
      {tfDescription && (
        <div className={styles.enteral_modal_column_three}>
          <div style={{ marginBottom: "20px" }}>
            <h4 style={{ fontWeight: "800", marginBottom: "2px" }}>
              {foundTF.name}
            </h4>
            <span>Provider: {foundTF.provider}</span>
          </div>
          <div>
            <span style={{ fontWeight: "700", paddingTop: "10px" }}>
              Description:
            </span>
            <p style={{ fontSize: "14px", marginTop: "3px" }}>
              {foundTF.description}
            </p>
          </div>
          <span style={{ fontWeight: "700" }}>Features:</span>
          <ul>
            {foundTF.features.map((item, i) => (
              <li key={i} style={{ fontSize: "13px", paddingTop: "5px" }}>
                {item}
              </li>
            ))}
          </ul>
          <div className={styles.enteral_modal_column_three_button_container}>
            <button
              className={styles.enteral_modal_column_three_button}
              onClick={handleClearTFDescription}>
              Close Tube Feeding Information
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnteralModalColumnThree;
