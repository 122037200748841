import React from "react";

const MalnutritionSelector = ({ type, name, value, handleChange }) => {
  return (
    <select name={name} value={value} onChange={handleChange}>
      {type.map((item) => (
        <option key={item.id} value={item.value}>
          {item.title}
        </option>
      ))}
    </select>
  );
};

export default MalnutritionSelector;
