import React from "react";

import styles from "../../css/PasswordRules.module.css";

const PasswordRules = () => {
  return (
    <div className={styles.password_rules}>
      <h4>Password Requirements</h4>
      <ul>
        <li>Lower Case Letter</li>
        <li>Upper Case Letter</li>
        <li>Special Character</li>
        <li>Password Length {">"} 8</li>
      </ul>
    </div>
  );
};

export default PasswordRules;
