import { React, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useVerify2faMutation } from "../../../app/api/authAPISlice";

import globalStyles from "../../../index.module.css";
import styles from "../../../css/TwoFA.module.css";
import { setCredentials } from "../../../app/auth/authSlice";

const TwoFaStandard = () => {
  const location = useLocation();
  const data = location.state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errMsg, setErrMsg] = useState("");
  const [token, setToken] = useState(Array(6).fill(""));
  const inputs = useRef([]);

  const [verify2fa] = useVerify2faMutation();

  const combinedToken = token.join("");

  const handleTokenInput = (e, i) => {
    const { value } = e.target;

    if (value.match(/^[0-9]*$/)) {
      const newOtp = [...token];
      newOtp[i] = value;
      setToken(newOtp);

      if (value && i < 6 - 1) {
        inputs.current[i + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, i) => {
    if (e.key === "Backspace" && !e.target.value && i > 0) {
      if (i > 0) {
        inputs.current[i - 1].focus();
      }
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      const res = await verify2fa({
        userId: data,
        token: combinedToken,
      }).unwrap();
      const accessToken = res.accessToken;
      dispatch(setCredentials({ accessToken: accessToken }));
      navigate("/dashboard");
    } catch (err) {
      if (!err.status) {
        setErrMsg("No Server Response");
      } else if (err.status === 404) {
        setErrMsg("User does not exist");
      } else if (err.status === 401) {
        setErrMsg("Unathorized");
      }
    }
  };

  return (
    <div className={globalStyles.body_wrapper}>
      <div className={styles.fa_wrapper_outer}>
        <div className={styles.fa_wrapper_inner}>
          <h3>Secondary Validation</h3>
          <div className={styles.fa_input_boxes_row}>
            {token.map((data, i) => (
              <input
                key={i}
                type="number"
                className={styles.fa_input_boxes}
                maxLength={1}
                value={data[i]}
                onKeyDown={(e) => handleKeyDown(e, i)}
                onChange={(e) => handleTokenInput(e, i)}
                ref={(el) => (inputs.current[i] = el)}
              />
            ))}
          </div>
          {errMsg && <p className={styles.fa_errmsg}>{errMsg}</p>}
          <div className={styles.fa_button_row}>
            <button className={styles.fa_button} onClick={handleVerify}>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFaStandard;
