import { apiSlice } from "./apiSlice";

export const longTermApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLongTermList: builder.query({
      query: () => "/api/longterm",
      providesTags: [{ type: "LongTerm", id: "LONGTERM" }],
    }),
    addNewLongTermItem: builder.mutation({
      query: (initialItem) => ({
        url: "/api/longterm",
        method: "POST",
        body: {
          ...initialItem,
        },
      }),
      invalidatesTags: [{ type: "LongTerm", id: "LONGTERM" }],
    }),
    updateLongTermItem: builder.mutation({
      query: (initialItem) => ({
        url: "/api/longterm",
        method: "PATCH",
        body: {
          ...initialItem,
        },
      }),
      invalidatesTags: [{ type: "LongTerm", id: "LONGTERM" }],
    }),
    deleteLongTermItem: builder.mutation({
      query: ({ id }) => ({
        url: "/api/longterm",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: [{ type: "LongTerm", id: "LONGTERM" }],
    }),
  }),
});

export const {
  useGetLongTermListQuery,
  useAddNewLongTermItemMutation,
  useUpdateLongTermItemMutation,
  useDeleteLongTermItemMutation,
} = longTermApiSlice;
