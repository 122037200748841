import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";

const listAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.assessnum - b.assessnum,
});

const initialState = listAdapter.getInitialState();

export const listApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getList: builder.query({
      query: () => "/api/list",
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
      transformResponse: (responseData) => {
        const loadedList = responseData.map((list) => {
          list.id = list._id;
          return list;
        });
        return listAdapter.setAll(initialState, loadedList);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "List", id: "LIST" },
            ...result.ids.map((id) => ({ type: "List", id })),
          ];
        } else return [{ type: "List", id: "LIST" }];
      },
    }),
    getItemByID: builder.query({
      query: (id) => `/api/list/${id}`,
      providesTags: [{ type: "List", id: "LIST" }],
    }),
    addNewListItem: builder.mutation({
      query: (initialItem) => ({
        url: "/api/list",
        method: "POST",
        body: {
          ...initialItem,
        },
      }),
      invalidatesTags: [{ type: "List", id: "LIST" }],
    }),
    updateItem: builder.mutation({
      query: (initialItem) => ({
        url: "/api/list",
        method: "PATCH",
        body: {
          ...initialItem,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "List", id: arg.id }],
    }),
    deleteItem: builder.mutation({
      query: ({ id }) => ({
        url: "/api/list",
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "List", id: arg.id }],
    }),
  }),
});

export const {
  useGetListQuery,
  useAddNewListItemMutation,
  useDeleteItemMutation,
  useUpdateItemMutation,
  useGetItemByIDQuery,
} = listApiSlice;

export const selectListResults = listApiSlice.endpoints.getList.select();

const selectListData = createSelector(
  selectListResults,
  (listResult) => listResult.data
);

export const {
  selectAll: selectAllLists,
  selectById: selectItemById,
  selectIds: selectItemIds,
} = listAdapter.getSelectors((state) => selectListData(state) ?? initialState);
