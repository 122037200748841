import { useState, useEffect } from "react";
import styles from "../../../../css/MalnutritionRisk.module.css";
import { malnutritionRiskScreen } from "../../../../utils/staticData";
import {
  titleFinderFunction,
  totalCalcFunction,
} from "../../../../utils/helperFunctions";
import { useUpdateItemMutation } from "../../../../app/api/listApiSlice";
import MalnutritionSelector from "./MalnutritionSelector";
import SaveButton from "../../../../components/buttons/SaveButton";

const MalnutritionRisk = ({ data, handleDataChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isSaveVisible, setSaveIsVisible] = useState(false);
  const [checked, setChecked] = useState(false);
  const [inputs, setInputs] = useState({
    id: data._id,
    values: data.values,
    malInput: data.malInput,
    prevMalScore: data.prevMalScore,
    prevMalDate: data.prevMalDate,
  });

  useEffect(() => {
    if (inputs.prevMalScore) {
      setChecked(true);
    }
  }, [inputs.prevMalScore]);

  const totalCalc = totalCalcFunction(inputs);
  const [updateItem, { isError, error }] = useUpdateItemMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;

    const titleFinder = titleFinderFunction(name, value);

    setInputs((prev) => ({
      ...prev,
      values: { ...prev.values, [name]: { num: value, title: titleFinder } },
    }));
  };

  const handleMalInput = (e) =>
    setInputs((prev) => ({ ...prev, malInput: e.target.value }));

  const handlePrevMalScore = (e) =>
    setInputs((prev) => ({ ...prev, prevMalScore: e.target.value }));

  const handlePrevMalDate = (e) =>
    setInputs((prev) => ({ ...prev, prevMalDate: e.target.value }));

  /// send state changes to database
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsVisible(true);

    await updateItem(inputs);

    setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    setTimeout(() => {
      setSaveIsVisible(true);
    }, 3000);

    setTimeout(() => {
      setSaveIsVisible(false);
    }, 5000);
  };

  return (
    <div>
      <div className={styles.malnutrition_checkbox_row}>
        <span style={{ marginRight: "1rem" }}>
          Previous Malnutrition Risk Screen?
        </span>
        <span>Yes/No: </span>
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
      </div>
      {checked && (
        <div className={styles.malnutrition_checkbox_row}>
          <span>Previous Score: </span>
          <select value={inputs.prevMalScore} onChange={handlePrevMalScore}>
            <option value=""></option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
          </select>
          <span style={{ marginLeft: "1rem" }}>Previous Date: </span>
          <input
            type="date"
            value={inputs.prevMalDate}
            onChange={handlePrevMalDate}
          />
        </div>
      )}
      <h4 className={styles.malnutrition_title}>
        A. Has Food intake declined over the past 3 months due to loss of
        appetite, digestive problems, chewing or swallowing difficulties
      </h4>
      <div className={styles.malnutrition_selector}>
        <MalnutritionSelector
          type={malnutritionRiskScreen.A}
          name="A"
          value={inputs.values.A.num}
          handleChange={handleChange}
        />
      </div>
      <h4 className={styles.malnutrition_title}>
        B. Weight loss during the last 3 months
      </h4>
      <div className={styles.malnutrition_selector}>
        <MalnutritionSelector
          type={malnutritionRiskScreen.B}
          name="B"
          value={inputs.values.B.num}
          handleChange={handleChange}
        />
      </div>
      <h4 className={styles.malnutrition_title}>C. Mobility</h4>
      <div className={styles.malnutrition_selector}>
        <MalnutritionSelector
          type={malnutritionRiskScreen.C}
          name="C"
          value={inputs.values.C.num}
          handleChange={handleChange}
        />
      </div>
      <h4 className={styles.malnutrition_title}>
        D. Has suffered psychological stress or acute disease in the past 3
        months
      </h4>
      <div className={styles.malnutrition_selector}>
        <MalnutritionSelector
          type={malnutritionRiskScreen.D}
          name="D"
          value={inputs.values.D.num}
          handleChange={handleChange}
        />
        <span style={{ marginLeft: "20px" }}>What is the reason?</span>
        <input
          type="text"
          name="malInput"
          value={inputs.malInput}
          disabled={!inputs.values.D.num || inputs.values.D.num === "2"}
          style={{ marginLeft: "8px" }}
          onChange={handleMalInput}
        />
      </div>
      <h4 className={styles.malnutrition_title}>
        E. Neuropsychological problems
      </h4>
      <div className={styles.malnutrition_selector}>
        <MalnutritionSelector
          type={malnutritionRiskScreen.E}
          name="E"
          value={inputs.values.E.num}
          handleChange={handleChange}
        />
      </div>
      <h4 className={styles.malnutrition_title}>
        F1. Body Mass Index (BMI weight in kg/height in m^2)
      </h4>
      <div className={styles.malnutrition_selector}>
        <MalnutritionSelector
          type={malnutritionRiskScreen.F1}
          name="F1"
          value={inputs.values.F1.num}
          handleChange={handleChange}
        />
      </div>
      <h4 className={styles.malnutrition_title}>F2. Calf circumference</h4>
      <div className={styles.malnutrition_selector}>
        <MalnutritionSelector
          type={malnutritionRiskScreen.F2}
          name="F2"
          value={inputs.values.F2.num}
          handleChange={handleChange}
        />
      </div>
      {totalCalc.num > 0 && (
        <div
          style={
            totalCalc.malName === "Malnourished"
              ? { width: "15rem", marginTop: "1rem" }
              : { width: "20rem", marginTop: "1rem" }
          }>
          <span>Total: </span>
          <span style={{ marginRight: "1rem" }}>{totalCalc.num}</span>
          <span>{totalCalc.malName && `(${totalCalc.malName})`}</span>
        </div>
      )}
      <div style={{ marginTop: "0.5rem" }}>
        <SaveButton
          title="Save"
          isVisible={isVisible}
          isSaveVisible={isSaveVisible}
          isError={isError}
          error={error}
          event={handleSubmit}
        />
      </div>
    </div>
  );
};

export default MalnutritionRisk;
