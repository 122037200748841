import styles from "../../../../css/AssessmentForm.module.css";

export default function VitMinList({ micro, handleNestedChange }) {
  return (
    <div className={styles.lower_form_vitmin_list}>
      <input
        type="checkbox"
        name="micronutrients.checked"
        checked={micro.checked}
        onChange={(e) => handleNestedChange(e, micro.id)}
      />
      <span>{micro.name}</span>
    </div>
  );
}
