import React from "react";
import Nav from "../navigation/Nav";
import globalStyles from "../../index.module.css";

const Spinner = () => {
  return (
    <div className={globalStyles.body_wrapper}>
      <div>
        <Nav />
      </div>
      <div style={{ paddingLeft: "20px" }}>
        <span className={globalStyles.spinner}></span>
      </div>
    </div>
  );
};

export default Spinner;
