//library imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

//styling
import styles from "../../../css/LongTermLists.module.css";

const LongTermViewer = ({ data, id, handleViewToggle }) => {
  const [filteredData] = data.filter((item) => item._id === id);

  const filteredFoodAllergy = filteredData.foodAllergy?.map(
    (item) => item.allergyname
  );

  const filteredFoodIntolerance = filteredData.FoodIntolerance.map(
    (item) => item.intolerancename
  );

  const filteredFoodPrefs = filteredData.inputFields.map((item) => item.value);

  const filteredSupplementList = filteredData.supplementList.map(
    (item) =>
      `${item.supname} => Date Started: ${new Date(
        item.supdate
      ).toLocaleDateString()}`
  );

  return (
    <div className={styles.lt_viewer}>
      <div className={styles.lt_viewer_title}>
        <h3>Summary</h3>
        <FontAwesomeIcon
          icon={faXmark}
          size="lg"
          onClick={() => handleViewToggle(false)}
          style={{
            paddingRight: "10px",
            paddingBottom: "10px",
            cursor: "pointer",
          }}
        />
      </div>
      <div>
        <span>Last Name: </span>
        <span>{filteredData.lastName}</span>
      </div>
      <div>
        <span>First Name: </span>
        <span>{filteredData.firstName}</span>
      </div>
      <div>
        <span>DOB: </span>
        <span>{new Date(filteredData.age).toLocaleDateString()}</span>
      </div>
      <div>
        <span>Sex: </span>
        <span>{filteredData.sex}</span>
      </div>
      <div>
        <span>PMHx: </span>
        <span>{filteredData.pmhx}</span>
      </div>
      <div>
        <span>Upper Dentition: </span>
        <span>{filteredData.upperDent}</span>
      </div>
      <div>
        <span>Lower Dentition: </span>
        <span>{filteredData.lowerDent}</span>
      </div>
      {filteredFoodAllergy.length > 0 && (
        <div>
          <span>Food Allergy: </span>
          <span>{filteredFoodAllergy.join(", ")}</span>
        </div>
      )}
      {filteredFoodIntolerance.length > 0 && (
        <div>
          <span>Food Intolerance: </span>
          <span>{filteredFoodIntolerance.join(", ")}</span>
        </div>
      )}
      {filteredData.adaptEquip && (
        <div>
          <span>Adaptive Equipment: </span>
          <span>{filteredData.adaptEquip}</span>
        </div>
      )}
      {filteredFoodPrefs.length > 0 && (
        <div>
          <span>Food Preferences: </span>
          <span>{filteredFoodPrefs.join(", ")}</span>
        </div>
      )}
      {filteredSupplementList.length > 0 && (
        <div>
          <span>Supplements: </span>
          <span>{filteredSupplementList.join(" // ")}</span>
        </div>
      )}
      <div>
        <span>Eating (Self-Performance): </span>
        <span>{filteredData.eatSelfPerform}</span>
      </div>
      <div>
        <span>Eating (Support Provided): </span>
        <span>{filteredData.eatSupportPerform}</span>
      </div>
      <div>
        <span>Ambulation: </span>
        <span>{filteredData.ambulation}</span>
      </div>
      <div>
        <span>Portion Size: </span>
        <span>{filteredData.portionSize}</span>
      </div>
      <div>
        <div>Previous Summary: </div>
        <span>{filteredData.prevSummary}</span>
      </div>
    </div>
  );
};

export default LongTermViewer;
