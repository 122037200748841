import { createSlice } from "@reduxjs/toolkit";

const initialState = { otp: null, isAuthenticated: false };

const twoFaSlice = createSlice({
  name: "twofa",
  initialState,
  reducers: {
    setOTP: (state, action) => {
      state.otp = action.payload;
    },
    setAuthentication: (state, action) => {
      state.isAuthenticated = action.payload;

      if (state.isAuthenitcated) console.log("First Authentication successful");
      if (!state.isAuthenticated) console.log("First Authentication inactive");
    },
  },
});

export const { setOTP, setAuthentication } = twoFaSlice.actions;

export default twoFaSlice.reducer;
