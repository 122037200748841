import { selectAllLists } from "../../../app/api/listApiSlice";
import { useSelector } from "react-redux";

//stles
import styles from "../../../css/Settings.module.css";

const ViewAllAssessments = () => {
  const assess = useSelector(selectAllLists);

  const currentAssessBWT = assess.filter(
    (item) => item.assessnum && item.location === "bwt"
  );

  const currentAssessWR = assess.filter(
    (item) => item.assessnum && item.location === "wr"
  );

  const allAssesseQueued = assess.filter(
    (item) => (item.assessnum === null || item.assessnum === 0) && item.user
  );

  return (
    <div className={styles.settings_view_all_assessments_outer_container}>
      <div className={styles.settings_inner_container}>
        <h4 className={styles.settings_view_all_title}>
          VIEW ALL ASSESSMENTS BWT
        </h4>
        <table className={styles.settings_view_all_assessments}>
          <thead className={styles.settings_view_all_assessments_head}>
            <tr>
              <th>Date</th>
              <th>User</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Assessment Type</th>
            </tr>
          </thead>
          <tbody>
            {currentAssessBWT.map((item) => (
              <tr key={item.id}>
                <td>{new Date(item.date).toLocaleDateString("en")}</td>
                <td>{item.user}</td>
                <td>{item.firstName}</td>
                <td>{item.lastName}</td>
                <td>{item.assessType}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.settings_inner_container}>
        <h4 className={styles.settings_view_all_title}>
          VIEW ALL ASSESSMENTS WR
        </h4>
        <table className={styles.settings_view_all_assessments}>
          <thead className={styles.settings_view_all_assessments_head}>
            <tr>
              <th>Date</th>
              <th>User</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Assessment Type</th>
            </tr>
          </thead>
          <tbody>
            {currentAssessWR.map((item) => (
              <tr key={item.id}>
                <td>{new Date(item.date).toLocaleDateString("en")}</td>
                <td>{item.user}</td>
                <td>{item.firstName}</td>
                <td>{item.lastName}</td>
                <td>{item.assessType}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.settings_inner_container}>
        <h4 className={styles.settings_view_all_title}>
          VIEW ALL ASSESSMENTS QUEUED
        </h4>
        <table className={styles.settings_view_all_assessments}>
          <thead className={styles.settings_view_all_assessments_head}>
            <tr>
              <th>Date</th>
              <th>User</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Assessment Type</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {allAssesseQueued.map((item) => (
              <tr key={item.id}>
                <td>{new Date(item.date).toLocaleDateString("en")}</td>
                <td>{item.user}</td>
                <td>{item.firstName}</td>
                <td>{item.lastName}</td>
                <td>{item.assessType}</td>
                <td>{item.location}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewAllAssessments;
