import { current } from "@reduxjs/toolkit";

import {
  ageCalc,
  getBMI,
  getBmiType,
  selectUBW,
  percentChangeCalc,
  lbsChangeCalculation,
  gettheraDietChecker,
  dateChange,
  getTextureChecker,
  vitaminRx,
  supplementRx,
  foodPrefsFunction,
  appetiteStimulationFunction,
  foodAllergiesFunction,
  malTitleFunction,
  totalCalcFunction,
  dietTypeChecker,
} from "./helperFunctions.js";

import { THERADIETCHECKER, TEXTURECHECKER } from "./staticData.js";

///Recommendations///
export const nutritionRecsFunction = (data) => {
  const totalRecs = [];

  data.nutritionRecs.forEach((e, i) => {
    totalRecs.push([i + 1, e.value].join(". "));
  });

  if (totalRecs.length > 0) {
    return `Recommendations: ${totalRecs.join(", ")}`;
  } else {
    return "";
  }
};

///Malnutrition Change
export const malnutritionRiskChangeFunction = (data) => {
  const { num } = totalCalcFunction(data);
  const prevMalScore = Number(data.prevMalScore);
  const prevMalDate = new Date(data.prevMalDate).toLocaleDateString("en", {
    timeZone: "UTC",
  });

  let currentMnaValue;
  let previousMnaValue;

  if (num >= 12 && num <= 14) {
    currentMnaValue = "normal nutrition";
  } else if (num >= 8 && num <= 11) {
    currentMnaValue = "at risk of malnutrition";
  } else if (num > 0 && num <= 7) {
    currentMnaValue = "malnourished";
  } else {
    currentMnaValue = "";
  }

  if (prevMalScore >= 12 && prevMalScore <= 14) {
    previousMnaValue = "normal nutrition";
  } else if (prevMalScore >= 8 && prevMalScore <= 11) {
    previousMnaValue = "at risk of malnutrition";
  } else if (prevMalScore > 0 && prevMalScore <= 7) {
    previousMnaValue = "malnourished";
  } else {
    previousMnaValue = "";
  }

  const normal = "normal nutrition";
  const atRisk = "at risk of malnutrition";
  const malnourished = "malnourished";

  if (!currentMnaValue || !previousMnaValue) return "";
  if (currentMnaValue === previousMnaValue)
    return ` => Similar to last MNA (${prevMalDate}) (${prevMalScore} of 14) (${previousMnaValue})`;
  if (previousMnaValue === atRisk && currentMnaValue === normal)
    return ` => Improvement from last MNA (${prevMalDate}) (${prevMalScore} of 14) (${previousMnaValue})`;
  if (previousMnaValue === atRisk && currentMnaValue === malnourished)
    return ` => Change from last MNA (${prevMalDate}) (${prevMalScore} of 14) (${previousMnaValue}})`;
  if (previousMnaValue === malnourished && currentMnaValue === atRisk)
    return ` => Improvement from last MNA (${prevMalDate}) (${prevMalScore} of 14) (${previousMnaValue})`;
  if (previousMnaValue === normal && currentMnaValue === atRisk)
    return ` => Change from last MNA (${prevMalDate}) (${prevMalScore} of 14) (${previousMnaValue})`;
  if (previousMnaValue === normal && currentMnaValue === malnourished)
    return ` => Change from last MNA (${prevMalDate}) (${prevMalScore} of 14) (${previousMnaValue})`;
  if (previousMnaValue === malnourished && currentMnaValue === normal)
    return ` => Change from last MNA (${prevMalDate}) (${prevMalScore} of 14) (${previousMnaValue})`;
};

///Malnutrition Risk Screen///
export const malnutritionRiskFunction = (data) => {
  const malnutritionData = malTitleFunction(data);
  const malnutritionTotal = totalCalcFunction(data);

  const prevMalMnAScore = malnutritionRiskChangeFunction(data);

  let malDiagnosis;

  if (malnutritionTotal.num <= 11 && malnutritionTotal.num >= 8) {
    malDiagnosis = `Mini Nutritional Assessment: (${
      malnutritionTotal.num
    } of 14) is at risk for malnutrition secondary to ${malnutritionData.join(
      ", "
    )}${prevMalMnAScore}`;
  } else if (malnutritionTotal.num <= 7 && malnutritionTotal.num >= 0) {
    malDiagnosis = `Mini Nutritional Assessment: (${
      malnutritionTotal.num
    } of 14) has malnutrition secondary to ${malnutritionData.join(
      ", "
    )}${prevMalMnAScore}`;
  } else if (malnutritionTotal.num >= 12) {
    malDiagnosis = `Mini Nutritional Assessment: (${malnutritionTotal.num} of 14) Normal Nutrition Status${prevMalMnAScore}`;
  }

  return { malName: malDiagnosis, total: malnutritionTotal };
};

///Nutrition Rx Develpment///
export const nutritionRxFunction = (data) => {
  const theraDiet = gettheraDietChecker(data);
  const textureMod = getTextureChecker(data);
  const micronutrients = vitaminRx(data);
  const supplementList = supplementRx(data)
    .map((x) => x.name)
    .join(", ");
  const foodPrefs = foodPrefsFunction(data);

  const weightOrder = data.weightOrder;
  const adaptiveEquipment = data.adaptEquip;
  const selectMenu = data.selectMenu;
  const appetiteStimulant = appetiteStimulationFunction(data);
  const foodAllergies = foodAllergiesFunction(data).allergy.join("  ");
  const foodIntolerance = foodAllergiesFunction(data).intolerance.join("  ");

  const nutritionRxa = [];
  const nutritionRxb = [];

  //diet / texture changes
  const dietCheck = THERADIETCHECKER.some(
    (diet) => diet.toLowerCase() === data.theraDiet.toLowerCase()
  );

  const textureCheck = TEXTURECHECKER.some(
    (texture) => texture.toLowerCase() === data.texture.toLowerCase()
  );

  if (dietCheck && data.theraDiet && textureCheck) {
    nutritionRxa.push(`${data.theraDiet}`);
  }

  if (!dietCheck && data.theraDiet && textureCheck) {
    nutritionRxa.push(`On ${data.theraDiet} secondary to ${theraDiet}`);
  }

  if (!dietCheck && data.theraDiet && !textureCheck && data.texture) {
    nutritionRxa.push(
      `On ${data.theraDiet} secondary to ${theraDiet}, ${textureMod}`
    );
  }

  if (dietCheck && !textureCheck && data.texture) {
    nutritionRxa.push(textureMod.charAt(0).toUpperCase() + textureMod.slice(1));
  }

  // micronutrients
  if (micronutrients) nutritionRxa.push(micronutrients);
  // supplements
  if (supplementList) nutritionRxa.push(supplementList);
  // food prefs
  if (foodPrefs) nutritionRxa.push(foodPrefs);
  // weight order
  if (weightOrder) nutritionRxa.push(weightOrder);
  // adaptive equipment
  if (adaptiveEquipment) nutritionRxa.push(adaptiveEquipment);
  // appetite stimulant
  if (appetiteStimulant) nutritionRxa.push(appetiteStimulant);
  // select menu
  if (selectMenu) nutritionRxa.push(`Select Menu`);
  //food allergies/intolerances
  if (foodAllergies && foodIntolerance) {
    nutritionRxa.push(
      `Food Allergy to ${foodAllergies} and Intolerance to ${foodIntolerance} will be noted in dietary profile, electronic medical record, and dietary tray card system`
    );
  } else if (foodAllergies) {
    nutritionRxa.push(
      `Food Allergy to ${foodAllergies} will be noted in dietary profile, electronic medical record, and dietary tray card system`
    );
  } else if (foodIntolerance) {
    nutritionRxa.push(
      `Food Intolerance to ${foodIntolerance} will be noted in dietary profile, electronic medical record, and dietary tray card system`
    );
  }

  // nutrition Rx setup
  nutritionRxa.forEach((e, i) => {
    nutritionRxb.push([i + 1, e].join(". "));
  });

  if (nutritionRxb.length > 0)
    return `Nutrition Rx: ${nutritionRxb.join(", ")}`;
};

/////////////////Nurition Summary Development//////////////////////////
export const nutritionSummaryFunction = (data) => {
  const recentWt = data.wt;
  const ptType = data.clientType;
  const assessType = data.assessType;
  const foodIntake = foodIntakeReference();
  const currentWt = currentWtNote();
  const dietType = dietTypeChecker(data);

  //Admission & Readmission
  const admitnoteP1 =
    data.admitType &&
    `${ptType} is ${
      assessType === "Admission" ? "admitted" : "readmitted"
    } to BTHC from ${data.admitLocal} with ${data.admitType}. `;
  const admitnoteP2 = data.pmhx && `PMHx of ${data.pmhx}. `;
  const admitnoteP3 = bmiNote() && `${bmiNote()}. `;
  const admitnoteP4 = currentWt && `${currentWt}. `;
  const admitnoteP5 =
    dietType &&
    ` ${ptType} with ${foodIntake} intake${
      dietTypeChecker(data) && ` on ${dietTypeChecker(data)}`
    }. `;
  const admitnoteP6 = data.currSummary && `${data.currSummary}`;
  const admitFinalNote = [
    admitnoteP1,
    admitnoteP2,
    admitnoteP3,
    admitnoteP4,
    admitnoteP5,
    admitnoteP6,
  ];
  //Significant Change & Annual Note
  const annualnoteP1 = `${ptType} is a ${
    assessType === "Annual"
      ? `${ageCalc(data.age)} years old ${data.sex} with `
      : `${ageCalc(data.age)} years old ${
          data.sex
        } with significant change of ${data.admitType}`
  }`;
  const annualnoteP2 = wtChange();
  const annualnoteP3 = `${ubwRangeChecker()} `;
  const annualFinalNote = [
    annualnoteP1,
    admitnoteP2,
    annualnoteP2,
    admitnoteP3,
    admitnoteP4,
    annualnoteP3,
    admitnoteP5,
    admitnoteP6,
  ];

  const quarterlynotP4 = currentWt && `${currentWt}`;
  //Quarterly & PRN
  const quarterlyFinalNote = [
    annualnoteP2,
    quarterlynotP4,
    annualnoteP3,
    admitnoteP3,
    admitnoteP5,
    admitnoteP6,
  ];

  function bmiNote() {
    const currentHt = data.ht;
    const bodyMassIndex = getBMI(data.ht, data.wt);
    const bodyMassIndexName = getBmiType(data.ht, data.wt);

    if (recentWt > 0 && currentHt > 0) {
      return `BMI of ${bodyMassIndex.toFixed(2)} (${bodyMassIndexName}) noted`;
    } else {
      return;
    }
  }

  function currentWtNote() {
    const wtDate = new Date(data.currWtDate).toLocaleDateString();
    const ubwStart = data.ubwLow;

    if (recentWt > 0 && ubwStart) {
      return `Current wt is (${wtDate}) ${recentWt} lbs`;
    } else if (recentWt > 0) {
      return `Current wt is (${wtDate}) ${recentWt} lbs`;
    } else {
      return;
    }
  }

  function foodIntakeReference() {
    const foodIntake = data.foodIntake;

    if (foodIntake === ">50%") return "fair to good";
    if (foodIntake === "~50%") return "fair";
    if (foodIntake === "<50%") return "poor";
    return;
  }

  function ubwRangeChecker() {
    const ubwStart = data.ubwLow;
    const ubwEnd = data.ubwHigh;
    const ubwPercentage = selectUBW(recentWt, ubwStart, ubwEnd);
    const ubwMonths = data.ubwMonths;
    let ubwLevel;

    const percentCalc =
      ubwPercentage !== 0.0 ? `${ubwPercentage?.toFixed(1)}%` : "";

    if (ubwPercentage === 0.0) {
      ubwLevel = "within";
    } else if (ubwPercentage > 0.0) {
      ubwLevel = "above";
    } else {
      ubwLevel = "below";
    }

    if (ubwStart) {
      return `, which is ${
        ubwPercentage === 0.0
          ? `${ubwLevel} usual body weight range (${ubwStart} - ${ubwEnd} lbs)`
          : `${percentCalc} ${ubwLevel} usual body weight range (${ubwStart} - ${ubwEnd} lbs)`
      }. Usual Body Weight is Average Weight Range (+/-10 lbs) within the past ${ubwMonths} months.`;
    } else {
      return ". ";
    }
  }

  function wtChange() {
    const [oneMonthWt, threeMonthWt, sixMonthWt] = data.wtChange;
    const oneMonthWtDate = new Date(oneMonthWt.wtdate).toLocaleDateString();
    const oneMonthWtChange = oneMonthWt.wtchange;
    const threeMonthWtDate = new Date(threeMonthWt.wtdate).toLocaleDateString();
    const threeMonthWtChange = threeMonthWt.wtchange;
    const sixMonthWtDate = new Date(sixMonthWt.wtdate).toLocaleDateString();
    const sixMonthWtChange = sixMonthWt.wtchange;

    const oneMonthChange = `(${oneMonthWtDate}) ${oneMonthWtChange} lbs. [${percentChangeCalc(
      recentWt,
      oneMonthWtChange
    ).toFixed(1)}% / ${lbsChangeCalculation(recentWt, oneMonthWtChange).toFixed(
      1
    )} lbs @ ${dateChange(data.currWtDate, oneMonthWt.wtdate)} days]`;

    const threeMonthChange = `(${threeMonthWtDate}) ${threeMonthWtChange} lbs. [${percentChangeCalc(
      recentWt,
      threeMonthWtChange
    ).toFixed(1)}% / ${lbsChangeCalculation(
      recentWt,
      threeMonthWtChange
    ).toFixed(1)} lbs @ ${dateChange(
      data.currWtDate,
      threeMonthWt.wtdate
    )} days]`;

    const sixMonthChange = `(${sixMonthWtDate}) ${sixMonthWtChange} lbs. [${percentChangeCalc(
      recentWt,
      sixMonthWtChange
    ).toFixed(1)}% / ${lbsChangeCalculation(recentWt, sixMonthWtChange).toFixed(
      1
    )} lbs @ ${dateChange(data.currWtDate, sixMonthWt.wtdate)} days]`;

    if (oneMonthWt.toggle && !threeMonthWt.toggle && !sixMonthWt.toggle) {
      return `${ptType} has ${oneMonthWt.wtchangetype.toLowerCase()} within the past month ${oneMonthChange}. `;
    } else if (
      !oneMonthWt.toggle &&
      threeMonthWt.toggle &&
      !sixMonthWt.toggle
    ) {
      return `${ptType} has ${threeMonthWt.wtchangetype.toLowerCase()} within the past month ${threeMonthChange}. `;
    } else if (
      !oneMonthWt.toggle &&
      !threeMonthWt.toggle &&
      sixMonthWt.toggle
    ) {
      return `${ptType} has ${sixMonthWt.wtchangetype.toLowerCase()} within the past month ${sixMonthChange}. `;
    } else if (oneMonthWt.toggle && threeMonthWt.toggle && !sixMonthWt.toggle) {
      return `${ptType} has ${oneMonthWt.wtchangetype.toLowerCase()} within the past month ${oneMonthChange}. Also, has ${
        threeMonthWt.wtchangetype
      } within the past three months ${threeMonthChange}. `;
    } else if (oneMonthWt.toggle && !threeMonthWt.toggle && sixMonthWt.toggle) {
      return `${ptType} has ${oneMonthWt.wtchangetype.toLowerCase()} within the past month ${oneMonthChange}. Also, has ${sixMonthWt.wtchangetype.toLowerCase()} within the past six months ${sixMonthChange}. `;
    } else if (!oneMonthWt.toggle && threeMonthWt.toggle && sixMonthWt.toggle) {
      return `${ptType} has ${threeMonthWt.wtchangetype.toLowerCase()} within the past three months ${threeMonthChange}. Also, has ${
        sixMonthWt.wtchangetype
      } within the past six months ${sixMonthChange}. `;
    } else if (
      !recentWt &&
      data.weightOrder !== "Weights D/C'd" &&
      (data.assessType === "Quarterly" ||
        data.assessType === "PRN" ||
        data.assessType === "Annual" ||
        data.assessType === "Significant Change")
    ) {
      return `${ptType} without weight taken within the past 30 days. `;
    } else {
      return;
    }
  }

  if (assessType === "Admission" || assessType === "Readmission") {
    return admitFinalNote.join("");
  } else if (assessType === "Annual" || assessType === "Significant Change") {
    return annualFinalNote
      .join("")
      .replaceAll("Resident", "Res")
      .replaceAll("weight", "wt");
  } else if (assessType === "Quarterly" || assessType === "PRN") {
    return quarterlyFinalNote
      .join("")
      .replaceAll("Resident", "Res")
      .replaceAll("weight", "wt");
  } else {
    return;
  }
};
