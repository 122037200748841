import React from "react";
import styles from "./css/AssessForm.module.css";

export default function AssessForm({
  type,
  styleWidth,
  stylePadding,
  style,
  children,
}) {
  return (
    <div className={styles.assess_form_container} style={(stylePadding, style)}>
      <div className={styles.assess_form} style={styleWidth}>
        <div className={styles.btn_style} style={styleWidth}>
          <h3 className={styles.container_title}>{type}</h3>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}
