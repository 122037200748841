import { tfs } from "../../../../utils/staticData";
import { tfCalculationFunction } from "../../../../utils/enteralHelperFunctions";
import { freeWaterFunction } from "../../../../utils/enteralHelperFunctions";

//styles
import styles from "../../../../css/EnteralModal.module.css";

const EnteralModalColumnTwo = ({
  data,
  inputs,
  handleChange,
  handleNestedChange,
  handleTfCheck,
  handleTFdescription,
}) => {
  const calc = tfCalculationFunction(inputs, data);
  const water = freeWaterFunction(inputs);

  return (
    <div>
      {inputs.enteralRecs && (
        <div className={styles.enteral_modal_column_two}>
          <h4>RECOMMENDATION</h4>
          <div>
            <span>Tube Feeding Method: </span>
            <select name="recTFmethod" onChange={handleChange}>
              <option value="bolus">Bolus</option>
              <option value="continuous">Continuous</option>
              <option value="cyclic">Cyclic</option>
            </select>
          </div>
          <div>
            {inputs.recTFmethod === "bolus" ? (
              <>
                <span>Tube Feeding mL per Bolus: </span>
                <input
                  type="number"
                  name="recTFRate"
                  value={inputs.recTFRate}
                  style={{ width: "30px", textAlign: "center" }}
                  onChange={handleChange}
                />
              </>
            ) : (
              <>
                <span>Tube Feeding Rate (mL/hr): </span>
                <input
                  type="number"
                  name="recTFRate"
                  style={{ width: "30px", textAlign: "center" }}
                  value={inputs.recTFRate}
                  onChange={handleChange}
                />
              </>
            )}
          </div>
          <div>
            {inputs.recTFmethod === "bolus" && (
              <>
                <span>How Many Boluses Per Day: </span>
                <input
                  type="number"
                  name="recTFperDay"
                  value={inputs.recTFperDay}
                  style={{ width: "30px", textAlign: "center" }}
                  onChange={handleChange}
                />
              </>
            )}
            {inputs.recTFmethod === "cyclic" && (
              <>
                <span>Hrs per day: </span>
                <input
                  type="number"
                  name="recTFperDay"
                  value={inputs.recTFperDay}
                  style={{ width: "30px", textAlign: "center" }}
                  onChange={handleChange}
                />
              </>
            )}
          </div>
          {calc.recTFvolume > 0 && (
            <div>
              <span style={{ fontSize: "13px" }}>
                Total TF Volume {calc.recTFvolume} mL/day
              </span>
            </div>
          )}
          <div className={styles.enteral_modal_column_two_table_container}>
            <table className={styles.enteral_modal_column_two_table}>
              <thead>
                <tr>
                  <th>Formula Name</th>
                  <th>Calories</th>
                  <th>Protein</th>
                  <th>Water</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tfs.map((tf) => (
                  <tr key={tf.id}>
                    <td>
                      <span
                        className={styles.enteral_modal_tube_feeding_name}
                        onClick={() => handleTFdescription(tf.id)}>
                        {tf.name}
                      </span>
                    </td>
                    <td>
                      {calc.recTFvolume ? tf.calories * calc.recTFvolume : 0}
                    </td>
                    <td>
                      {calc.recTFvolume
                        ? Math.round(tf.protein * calc.recTFvolume)
                        : 0}
                    </td>
                    <td>
                      {calc.recTFvolume
                        ? Math.round(tf.fluid * calc.recTFvolume)
                        : 0}
                    </td>
                    <td>
                      {calc.recTFvolume ? (
                        <>
                          <input
                            type="checkbox"
                            checked={inputs.selectedOption === tf.id}
                            onChange={(e) => handleTfCheck(e, tf.id)}
                            disabled={
                              inputs.selectedOption &&
                              inputs.selectedOption !== tf.id
                            }
                          />
                          <span> Select </span>
                        </>
                      ) : (
                        <span></span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.enteral_modal_flush_columns}>
            <h3>Free Water Flush</h3>
            <div className={styles.enteral_modal_flush_row}>
              <span style={{ fontSize: "14px" }}>
                Number of Free H20 Flushes{" "}
              </span>
              <input
                type="text"
                name="recFreeFlush.freeTimes"
                value={inputs.recFreeFlush.freeTimes}
                onChange={handleNestedChange}
              />
            </div>
            <div className={styles.enteral_modal_flush_row}>
              <span style={{ fontSize: "14px" }}>Amount per Flush(mL) </span>
              <input
                type="text"
                name="recFreeFlush.freeRate"
                value={inputs.recFreeFlush.freeRate}
                onChange={handleNestedChange}
              />
            </div>
            {water.recFreeWaterFlush > 0 && (
              <div>
                <span style={{ fontSize: "13px" }}>
                  {water.recFreeWaterFlush} mL/day
                </span>
              </div>
            )}
          </div>
          {inputs.recTFmethod === "bolus" && (
            <div className={styles.enteral_modal_flush_columns}>
              <h3>Bolus Flushes</h3>
              <div className={styles.enteral_modal_flush_row}>
                <span style={{ fontSize: "14px" }}>Number of Boluses </span>
                <input
                  type="text"
                  name="recBolusFlush.bolusTimes"
                  value={inputs.recBolusFlush.bolusTimes}
                  onChange={handleNestedChange}
                />
              </div>
              <div className={styles.enteral_modal_flush_row}>
                <span style={{ fontSize: "14px" }}>
                  Flushes Before and After Bolus{" "}
                </span>
                <input
                  type="text"
                  name="recBolusFlush.bolusRate"
                  value={inputs.recBolusFlush.bolusRate}
                  onChange={handleNestedChange}
                />
              </div>
              {water.recBolusWaterFlush > 0 && (
                <div>
                  <span style={{ fontSize: "13px" }}>
                    {water.recBolusWaterFlush} mL/day
                  </span>
                </div>
              )}
            </div>
          )}
          <div>
            <span>{inputs.recTubeFeed}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnteralModalColumnTwo;
