import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NESTED_IDS } from "../../../../utils/staticData";

const FoodAllergies = ({ allergy, handleNestedChange, handleRemove }) => {
  return (
    <>
      <input
        type="text"
        name="foodAllergy.allergyname"
        placeholder={`Food Allergy #${allergy.allergynum}`}
        value={allergy.allergyname}
        onChange={(e) => handleNestedChange(e, allergy.id)}
        style={{ width: "9rem", marginLeft: "10px" }}
      />
      <span style={{ marginRight: "5px" }}>
        <FontAwesomeIcon
          icon={faTrash}
          onClick={(e) => handleRemove(NESTED_IDS.allergy, allergy.id)}
          style={{
            color: "#6e6d70",
            cursor: "pointer",
          }}
        />
      </span>
    </>
  );
};

export default FoodAllergies;
