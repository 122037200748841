import { nutrientCalc } from "../../../../utils/helperFunctions";
import { tfCalculationFunction } from "../../../../utils/enteralHelperFunctions";
import { freeWaterFunction } from "../../../../utils/enteralHelperFunctions";
import { tfs } from "../../../../utils/staticData";

import styles from "../../../../css/EnteralModal.module.css";

const EnteralModalColumnOne = ({
  data,
  inputs,
  handleChange,
  handleChecked,
  handleNestedChange,
}) => {
  const calc = tfCalculationFunction(inputs, data);
  const water = freeWaterFunction(inputs);
  const total = [
    calc.tfVolume,
    water.freeWaterFlush,
    water.medWaterFlush,
    water.bolusWaterFlush,
  ];

  const nutrient = nutrientCalc(data);
  const totalvolume = total.reduce((a, b) => a + b, 0);

  return (
    <div className={styles.enteral_modal_column_one}>
      <div className={styles.enteral_modal_inputs}>
        <div>
          <div className={styles.enteral_modal_column_one_div}>
            <span style={{ fontSize: "14px" }}>
              Current wt:{" "}
              {data.wt
                ? `${Math.round(data.wt / 2.2)} kg`
                : "No Weight Inputed Currently"}
            </span>
          </div>
          <div className={styles.enteral_modal_column_one_div}>
            <span style={{ fontSize: "14px" }}>
              Calorie Needs:{" "}
              {nutrient.nutrientCalories
                ? nutrient.nutrientCalories
                : "No Calorie Needs Inputed"}
            </span>
          </div>
          <div className={styles.enteral_modal_column_one_div}>
            <span style={{ fontSize: "14px" }}>
              Protein Needs:{" "}
              {nutrient.nutrientProtein
                ? nutrient.nutrientProtein
                : "No Protein Needs Inputed"}
            </span>
          </div>
          <div className={styles.enteral_modal_column_one_div}>
            <span style={{ fontSize: "14px" }}>
              Fluid Needs:{" "}
              {nutrient.nutrientFluids
                ? nutrient.nutrientFluids
                : "No Protein Needs Inputed"}
            </span>
          </div>
          <div className={styles.enteral_modal_column_one_div}>
            <span style={{ fontSize: "14px" }}>Tube Feeeding Type: </span>
            <select name="tfType" value={inputs.tfType} onChange={handleChange}>
              <option value=""></option>
              {tfs.map((item, i) => (
                <option key={i} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className={styles.enteral_modal_column_one_div}>
            <span style={{ fontSize: "14px" }}>Tube Feeding Method: </span>
            <select name="currTFmethod" onChange={handleChange}>
              <option value="bolus">Bolus</option>
              <option value="continuous">Continuous</option>
              <option value="cyclic">Cyclic</option>
            </select>
          </div>
          <div className={styles.enteral_modal_column_one_div}>
            {inputs.currTFmethod === "bolus" ? (
              <>
                <span style={{ fontSize: "14px" }}>
                  Tube Feeding mL per Bolus:{" "}
                </span>
                <input
                  type="text"
                  name="tfRate"
                  value={inputs.tfRate}
                  style={{ width: "30px" }}
                  onChange={handleChange}
                />
              </>
            ) : (
              <>
                <span style={{ fontSize: "14px" }}>
                  Tube Feeding Rate (mL/hr):{" "}
                </span>
                <input
                  type="text"
                  name="tfRate"
                  style={{ width: "30px" }}
                  value={inputs.tfRate}
                  onChange={handleChange}
                />
              </>
            )}
          </div>
          <div className={styles.enteral_modal_column_one_div}>
            {inputs.currTFmethod === "bolus" ? (
              <>
                <span style={{ fontSize: "14px" }}>
                  How Many Boluses Per Day:{" "}
                </span>
                <input
                  type="text"
                  name="tfPerDay"
                  value={inputs.tfPerDay}
                  style={{ width: "30px" }}
                  onChange={handleChange}
                />
              </>
            ) : (
              <>
                <span style={{ fontSize: "14px" }}>Hrs/Times per day: </span>
                <input
                  type="text"
                  name="tfPerDay"
                  value={inputs.tfPerDay}
                  style={{ width: "30px" }}
                  onChange={handleChange}
                />
              </>
            )}
          </div>
          {calc.tfVolume > 0 && (
            <div className={styles.enteral_modal_column_one_div}>
              <span style={{ fontSize: "13px" }}>
                Total TF Volume {calc.tfVolume} mL/day
              </span>
            </div>
          )}
          {calc.tfVolume > 0 && (
            <div className={styles.enteral_modal_column_one_div}>
              <span style={{ fontSize: "13px" }}>
                Total Volume (TF & Flush) {totalvolume} mL/day
              </span>
            </div>
          )}
        </div>
        <div>
          <div className={styles.enteral_modal_container_columns}>
            <div className={styles.enteral_modal_flush_columns}>
              <h3>Free Water Flush</h3>
              <div className={styles.enteral_modal_flush_row}>
                <span style={{ fontSize: "14px" }}>
                  Number of Free H20 Flushes{" "}
                </span>
                <input
                  type="text"
                  name="freeFlush.freeTimes"
                  value={inputs.freeFlush.freeTimes}
                  onChange={handleNestedChange}
                />
              </div>
              <div className={styles.enteral_modal_flush_row}>
                <span style={{ fontSize: "14px" }}>Amount per Flush(mL) </span>
                <input
                  type="text"
                  name="freeFlush.freeRate"
                  value={inputs.freeFlush.freeRate}
                  onChange={handleNestedChange}
                />
              </div>
              {water.freeWaterFlush > 0 && (
                <div>
                  <span style={{ fontSize: "13px" }}>
                    {water.freeWaterFlush} mL/day
                  </span>
                </div>
              )}
            </div>
            <div className={styles.enteral_modal_flush_columns}>
              <h3>Medication Flushes</h3>
              <div className={styles.enteral_modal_flush_row}>
                <span style={{ fontSize: "14px" }}>Number of Medications </span>
                <input
                  type="text"
                  name="medFlush.medTimes"
                  value={inputs.medFlush.medTimes}
                  onChange={handleNestedChange}
                />
              </div>
              <div className={styles.enteral_modal_flush_row}>
                <span style={{ fontSize: "14px" }}>
                  Flushes Before and After Medications (mL){" "}
                </span>
                <input
                  type="text"
                  name="medFlush.medRate"
                  value={inputs.medFlush.medRate}
                  onChange={handleNestedChange}
                />
              </div>
              {water.medWaterFlush > 0 && (
                <div className={styles.enteral_modal_column_one_div}>
                  <span style={{ fontSize: "13px" }}>
                    {water.medWaterFlush} mL/day
                  </span>
                </div>
              )}
            </div>
          </div>
          {inputs.currTFmethod === "bolus" && (
            <div className={styles.enteral_modal_flush_columns}>
              <h3>Bolus Flushes</h3>
              <div className={styles.enteral_modal_flush_row}>
                <span style={{ fontSize: "14px" }}>Number of Boluses </span>
                <input
                  type="text"
                  name="bolusFlush.bolusTimes"
                  value={inputs.bolusFlush.bolusTimes}
                  onChange={handleNestedChange}
                />
              </div>
              <div className={styles.enteral_modal_flush_row}>
                <span style={{ fontSize: "14px" }}>
                  Flushes Before and After Bolus{" "}
                </span>
                <input
                  type="text"
                  name="bolusFlush.bolusRate"
                  value={inputs.bolusFlush.bolusRate}
                  onChange={handleNestedChange}
                />
              </div>
              {water.bolusWaterFlush > 0 && (
                <div>
                  <span style={{ fontSize: "13px" }}>
                    {water.bolusWaterFlush} mL/day
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={styles.enteral_modal_column_one_div}>
          <span>Recommendations ?</span>
          <input
            type="checkbox"
            name="enteralRecs"
            checked={inputs.enteralRecs}
            onChange={handleChecked}
          />
        </div>
      </div>
    </div>
  );
};

export default EnteralModalColumnOne;
