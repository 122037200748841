import IBW from "./IBW";
import UBW from "./UBW";
import WtChangeInputs from "./WtChangeInputs";
import Equations from "./Equations";
import InputFields from "./InputFields";
import Labs from "./Labs";
import BMI from "./BMI";
import NutrientNeeds from "./NutrientNeeds";

import { ageCalc } from "../../../../utils/helperFunctions";
import styles from "../../../../css/AssessmentForm.module.css";
import StandardButton from "../../../../components/buttons/StandardButton";
import useAuth from "../../../../hooks/useAuth";

export default function MiddleAssess({
  inputs,
  handleChange,
  handleNestedChange,
  handleNestedLabChange,
  handleRemove,
  handleAddition,
}) {
  const { location } = useAuth();
  const age = ageCalc(inputs.age);
  const kg = Number(inputs.wt) / 2.2;

  return (
    <>
      <h4 className={styles.section_title}>
        Weights \\ Nutrient Needs \\ Labs \\ Food Prefs
      </h4>
      <div className={styles.form_mid_section_container}>
        <div className={styles.form_mid_section_left}>
          <div>
            <h3 className={styles.form_wt_title}>Weights:</h3>
            <label>
              Height:
              <input
                type="text"
                name="ht"
                maxLength={2}
                value={inputs.ht > 0 ? inputs.ht : ""}
                placeholder=""
                onChange={handleChange}
                style={{ width: "2rem" }}></input>
            </label>
            <span>Inches</span>
          </div>
          <div>
            <label>
              Weight:
              <input
                type="number"
                name="wt"
                value={inputs.wt}
                onChange={handleChange}
                style={{ width: "2.5rem" }}></input>
            </label>
            <span>Lbs</span>
            {inputs.wt > 0 && (
              <span
                style={{
                  marginLeft: "0.75rem",
                  fontSize: "15px",
                }}>{`(${kg.toFixed(1)} kg)`}</span>
            )}
          </div>
          <BMI inputs={inputs} />
          <IBW inputs={inputs} handleChange={handleChange} data={inputs} />
          <div>
            <span>UBW:</span>
            <input
              name="ubwLow"
              type="number"
              value={inputs.ubwLow}
              placeholder="Low"
              onChange={handleChange}
              style={{ width: "2rem" }}
            />
            <span style={{ paddingLeft: "0.50rem", paddingRight: "0.50rem" }}>
              to
            </span>
            <input
              name="ubwHigh"
              type="text"
              value={inputs.ubwHigh}
              placeholder="High"
              onChange={handleChange}
              style={{ width: "2rem" }}
            />
            <span style={{ paddingLeft: "0.50rem", paddingRight: "0.50rem" }}>
              How many Months?
            </span>
            <input
              name="ubwMonths"
              type="text"
              value={inputs.ubwMonths}
              onChange={handleChange}
              style={{ width: "2rem" }}
            />
          </div>
          <UBW inputs={inputs} />
          <div>
            <label>
              Weight Order:
              {location === "bwt" ? (
                <input
                  type="text"
                  name="weightOrder"
                  value={inputs.weightOrder}
                  onChange={handleChange}
                  style={{
                    width: "23rem",
                    padding: "0.1rem",
                    justifyContent: "center",
                  }}
                />
              ) : (
                <select
                  name="weightOrder"
                  value={inputs.weightOrder}
                  onChange={handleChange}>
                  <option value=""></option>
                  <option value="Monthly Weights">Monthly</option>
                  <option value="Weekly Weights">Weekly</option>
                  <option value="Daily Weights">Daily</option>
                  <option value="Weights D/C'd">D/C</option>
                </select>
              )}
            </label>
          </div>
          <div className={styles.form_wt_change_top}>
            <span>Current Wt:</span>
            <input
              name="currwt"
              type="number"
              disabled
              value={inputs.wt}
              onChange={handleChange}
              style={{ width: "2rem" }}
            />
            <span>lbs</span>
            <input
              type="date"
              name="currWtDate"
              placeholder="Date Obtained"
              value={inputs.currWtDate}
              onChange={handleChange}
            />
          </div>
          {inputs.wtChange.map((w) => (
            <WtChangeInputs
              key={w.id}
              w={w}
              inputs={inputs}
              handleNestedChange={handleNestedChange}
            />
          ))}
          <h3 className={styles.form_calorie_calculation_title}>
            Nutrition Needs:
          </h3>
          <Equations inputs={inputs} handleChange={handleChange} age={age} />
          <div className={styles.form_calorie_needs}>
            <div>
              <span>Weight type for Calculation:</span>
              <select
                name="wttype"
                value={inputs.wttype}
                onChange={handleChange}>
                <option value="currentwt">Current Weight</option>
                <option value="ibwwt">Ideal Body Weight</option>
              </select>
            </div>
            <NutrientNeeds inputs={inputs} handleChange={handleChange} />
          </div>
        </div>
        <div className={styles.form_mid_section_right}>
          <h3 className={styles.form_mid_section_right_title}>Labs:</h3>
          <div className={styles.form_mid_section_right_values}>
            {inputs.labs.map((item) => (
              <Labs
                item={item}
                key={item.id}
                inputs={inputs}
                handleNestedChange={handleNestedChange}
                handleNestedLabChange={handleNestedLabChange}
              />
            ))}
          </div>
          <h3 className={styles.form_mid_section_right_title}>Food Prefs:</h3>
          <div>
            {inputs.inputFields.length === 0 && (
              <span>Click the button below to add a field</span>
            )}
            {inputs.inputFields.map((item, index) => (
              <InputFields
                item={item}
                key={item.id}
                handleNestedChange={handleNestedChange}
                handleRemove={handleRemove}
              />
            ))}
            <StandardButton
              event={handleAddition}
              name="inputFields"
              title="Add New Preferences"
              style={{ paddingTop: "6px" }}
            />
          </div>
          <h3 className={styles.form_mid_section_right_title}>
            Selective Menu
          </h3>
          <div className={styles.middle_assess_radio_button}>
            <span>Yes:</span>
            <input
              type="checkbox"
              name="selectMenu"
              checked={inputs.selectMenu}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}
